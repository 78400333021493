import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Heimdall from "../../api/Heimdall";
import APIRequest from "../../api/APIRequest";

import { IOrganisation, IUser } from "../../api/ASuiteModels";

import Color from "../../resources/colors";
import ArrowIcon from "../../resources/images/arrow-icon.svg";

import UserList from "../../components/UserList";
import { GenericPopupProps } from "../../components/GenericPopup";
import { VerticalFlex, SaveButton, Button, Input, HorizontalFlex } from "../../pandora/styled";
import { ItemContainerAnimation } from "../../pandora/animations";
import LoadingComponent from "../LoadingComponent";



const ContentContainer = styled(VerticalFlex)<{focused: boolean}>`
    position: relative;
    height: auto;
    width: auto;
    padding: 20px;
    margin-bottom: 15px;

    border-radius: 5px;
    border-left: 3px solid transparent;
    background-color: ${Color.darkGrey};
    overflow: visible;

    cursor: pointer;
    user-select: none;

    transition: 0.3s ease;

    ${props => !props.focused ? `    
        &: hover {
            border-left: 3px solid ${Color.green};
        }
    ` 
    : 
    `
        border-left: 3px solid ${Color.green};
    `
    }
`

const InviteContainer = styled(VerticalFlex)`
    position: relative;
    height: auto;
    width: auto;
    margin-top: 20px;
    animation: ${ItemContainerAnimation} 0.3s 1;
`

const Arrow = styled("div")<{rotate: boolean}>`
    position: absolute;
    width: 15px;
    height: 15px;

    top: 25px;
    right: 25px;

    transition: 0.3s ease;
    transform: rotateX(${props => props.rotate ? 180 : 0}deg);

    background-image: url(${ArrowIcon});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

const Title = styled("h1")<{size: number, css?: string}>`
    margin: 0;
    text-align: left;
    font-size: ${props => props.size}px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: white;

    ${props => props.css ?? ""};
`

const BreakLine = styled("div")<{css?: string}>`
    width: 100%;
    height: 2px;
    background-color: ${Color.lightGrey};
    margin-top: 10px;
    margin-bottom: 20px;

    ${props => props.css ?? ""}
`



enum RequestType {
    GetUsers = "GetUsers",
    GetPendingUsers = "GetPendingUsers",
    InviteUser = "InviteUser"
}






interface OrganisationUserItemProps {
    organisation: IOrganisation;
    focused: boolean;
    isAtritec: boolean;
    setFocusOrg?: (o: IOrganisation) => void;
    setPopup: (popup: GenericPopupProps) => void;
}

const OrganisationUserItem = (props: OrganisationUserItemProps): React.ReactElement => {

    // State
    const { organisation, focused, isAtritec, setPopup, setFocusOrg } = props;

    const [users, setUsers] = useState<IUser[] | null>(null);
    const [pendingUsers, setPendingUsers] = useState<IUser[] | null>(null);

    // Flag indicates if changes have been made 
    const [hasChanges, setHasChanges] = useState<boolean>(false);

    // Save IDs of edited users. 
    const [editedUserIDs, setEditedUsers] = useState<string[] | null>([]);

    const [isInvitingUser, setInvitingUser] = useState<boolean>(false);
    const [newUsername, setNewUsername] = useState<string>("");
    const [newUserEmail, setNewUserEmail] = useState<string>("");

    // Map request type to corresponding 
    // API request for employee or User since they 
    // expect different endpoints for the equivalent data. 
    const RequestMap = {
        [RequestType.GetUsers]: isAtritec ? APIRequest.getAllEmployees : APIRequest.getUsersForOrganistion,
        [RequestType.GetPendingUsers]: isAtritec ? APIRequest.getPendingEmployees : APIRequest.getPendingUsersForOrganistion,
        [RequestType.InviteUser] : isAtritec ? APIRequest.inviteEmployee : APIRequest.inviteUser
    }

    // Effects
    useEffect(() => {
        
        // Request users from database. 
        const getUsers = async () => { 
            try {   
                // Request data
                const payload = {id: organisation.id}
                const _users: IUser[] = await Heimdall.request(RequestMap[RequestType.GetUsers], payload) as IUser[]    
                const _pendingUsers: IUser[] = await Heimdall.request(RequestMap[RequestType.GetPendingUsers], payload) as IUser[]
                
                // Set data state
                setUsers(_users);
                setPendingUsers(_pendingUsers);
            } catch (e) {
                const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
                setPopup({
                    title: "Ett fel uppstod.",
                    message: message,
                    color: Color.red,
                    setPopup: setPopup
                }) 
            }
        }

        getUsers()
    }, []);


    // Actions

    /**
     * Set invite UI state
     */
    const setInviting = (e) => {
        e.stopPropagation()
        setNewUsername("");
        setNewUserEmail("")
        setInvitingUser(!isInvitingUser);
    }

    /**
     * Save user made changes. 
     * @param e 
     * @param showPopup Flag to show popup on save successful. 
     */
    const saveChanges = (e, showPopup: boolean = true) => { 
        e.stopPropagation()

        // Iterate through edited user IDs
        editedUserIDs.forEach(async (userID: string) => {

            // Retrieve user object with ID 
            const _usersWithID: IUser[] = users.filter(u => u.id === userID)
            if (_usersWithID.length !== 1) 
                throw new Error(`Kunde inte uppdatera användare med id ${userID}`)
            const user = _usersWithID[0];

            try {
                // Construct payload
                const payload = {
                    id: user.id,
                    name: user.name,
                    email: user.email,
                }

                // Role only applies to employees
                if (isAtritec) payload["role"] = user.role;

                // Request user update
                const _ = await Heimdall.request(APIRequest.updateUser, payload);
                setHasChanges(false);
    
                if (showPopup) {
                    setPopup({
                        title: "Sparat!",
                        message: "Ändringarna har sparats.",
                        color: Color.green,
                        setPopup: setPopup
                    }) 
                }
            } catch (e) {
                const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
                setPopup({
                    title: "Ett fel uppstod.",
                    message: message,
                    color: Color.red,
                    setPopup: setPopup
                }) 
            }
        })

        // Reset state after update
        setEditedUsers([]);  
        setHasChanges(false);      
    }

    /**
     * Handle user edit event.
     * @param user User instance to apply updates on. 
     * @param update Update object with keys to update. 
     */
    const editUser = (user: IUser, update: Record<string, string>) => {

        // Update user instance
        const _users = [...users]
        const _newUser = {...user, ...update}
        _users.splice(users.indexOf(user), 1, _newUser)
        setUsers(_users);

        // Update edited user instance
        if (!editedUserIDs.includes(user.id)) {
            setEditedUsers([...editedUserIDs, user.id])
            if (!hasChanges) setHasChanges(true)
        }
    }

    /**
     * Request deletion of pending user invitation. 
     * @param e 
     * @param user 
     */
    const deletePendingUser = async (e, user: IUser) => {
        e.stopPropagation()
        try {
            // Request pending user removal
            const payload = {id: user.id}
            const _ = await Heimdall.request(APIRequest.deletePendingUser, payload)
            
             // Remove pending user locally
            const _pendingUsers = [...pendingUsers]
            _pendingUsers.splice(pendingUsers.indexOf(user), 1)
            setPendingUsers(_pendingUsers);
        } catch (e) {
            const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
            setPopup({
                title: "Ett fel uppstod.",
                message: message,
                color: Color.red,
                setPopup: setPopup
            }) 
        }
    }
    
    /**
     * Request user invitation addition. 
     */
    const inviteUser = async (e) => {
        e.stopPropagation()
        try {
            if (newUsername.length === 0 || newUserEmail.length === 0) 
                throw new Error("Ett eller flera fält är tomma.")

            // Request user invitation
            const payload = {
                id: organisation.id,
                name: newUsername,
                email: newUserEmail
            }

            // Created user instance. 
            const _newUser = await Heimdall.request(RequestMap[RequestType.InviteUser], payload) as IUser
            
             // Add invited user locally
            const _pendingUsers = [...pendingUsers, _newUser]
            setPendingUsers(_pendingUsers);
            setNewUsername("")
            setNewUserEmail("")
        } catch (e) {
            const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
            setPopup({
                title: "Ett fel uppstod.",
                message: message,
                color: Color.red,
                setPopup: setPopup
            }) 
        }
    }



    return (
        <ContentContainer 
            focused=    {focused}
            onClick=    {() => {if (setFocusOrg !== undefined) setFocusOrg(focused ? null : organisation)}}
        >
            {hasChanges && 
                <SaveButton 
                    onClick={(e) => saveChanges(e, true)}
                >Spara</SaveButton>
            } 
            {!isAtritec && <Arrow rotate={focused}/>}
            {!isAtritec && <Title size={18}>{organisation.name}</Title>}
            {focused && 
                <div>
                {users !== null ? 
                    <div>
                        <Title 
                            size={13} 
                            css={`font-weight: bold; margin-top: ${isAtritec ? 10 : 40}px;`}
                        >Användare</Title>
                        <BreakLine />
                        {users.length !== 0 ? 
                            <UserList 
                                users=              {users} 
                                isEmployeeTable=    {isAtritec}
                                includeID=          {true}
                                deletable=          {false}
                                editable=           {true}
                                editUser=           {editUser}
                            /> 
                        :
                            <Title
                                size=   {12}
                                css=    {"font-weight: normal;"}
                            >Inga användare</Title>
                        }
                    </div>
                    :
                    <LoadingComponent message="Hämtar användare..."/>
                }
                {pendingUsers !== null ? 
                    <div>
                        <Title 
                            size={13} 
                            css="font-weight: bold; margin-top: 40px;"
                        >Inbjudningar</Title>
                        <BreakLine />
                        {pendingUsers.length !== 0 ? 
                            <UserList 
                                users=              {pendingUsers} 
                                isEmployeeTable=    {isAtritec}
                                includeID=          {true}
                                editable=           {false}
                                deletable=          {true}
                                deleteUser=         {deletePendingUser}
                            /> 
                        :
                            <Title
                                size=   {12}
                                css=    {"font-weight: normal;"}
                            >Inga inbjudningar</Title>
                        }
                    </div>
                    :
                    <LoadingComponent message="Hämtar inbjudningar..."/>
                }
                <BreakLine css={"margin-top: 40px;"} />
                <Button
                    color=      {!isInvitingUser ? Color.green : Color.red}
                    hoverColor= {Color.extraLightGrey}
                    css=        {"width: 100px; margin-top: 30px; font-size: 12px;"}
                    onClick=    {setInviting}
                >{!isInvitingUser ? "+ Lägg till" : "Avbryt"}</Button>
                {isInvitingUser && 
                    <InviteContainer>
                        <Title size={12}>Bjud in anställda</Title>
                        <HorizontalFlex css={"margin-top: 15px;"}>
                            <Input 
                                focusBorder=    {false}
                                type=           "text"
                                placeholder=    "Namn"
                                value=          {newUsername}
                                onChange=       {(e) => setNewUsername(e.target.value)}
                                onClick=        {(e) => e.stopPropagation()}
                                fontSize=       {12}
                                css=            {`background-color: ${Color.lightGrey}; margin-top: 0px; margin-right: 15px;`}
                            />   
                            <Input 
                                focusBorder=    {false}
                                type=           "email"
                                placeholder=    "Email"
                                value=          {newUserEmail}
                                onChange=       {(e) => setNewUserEmail(e.target.value)}
                                onClick=        {(e) => e.stopPropagation()}
                                fontSize=       {12}
                                css=            {`background-color: ${Color.lightGrey}; margin-top: 0px; margin-right: 15px;`}
                            />   
                            <Button
                                color=      {Color.green}
                                hoverColor= {Color.lightGreen}
                                css=        {"min-width: 50px; font-size: 20px; line-height: 40px;"}
                                onClick=    {(e) => inviteUser(e)}
                            >+</Button>
                        </HorizontalFlex>
                    </InviteContainer>
                }
                </div>
            }
        </ContentContainer>
    )
}


export default OrganisationUserItem;