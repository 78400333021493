import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation, useHistory } from "react-router-dom";
import qs from "query-string";

import Heimdall from "../../api/Heimdall";
import APIRequest from "../../api/APIRequest";
import { Site, SiteRoute } from "../../routes/site";

import Color from "../../resources/colors";
import LogoIcon from "../../resources/images/asuite-logo.png";
import BackgroundImage from "../../resources/images/background-image-4.png";

import { ItemContainerAnimation } from "../../pandora/animations";
import { HorizontalFlex, VerticalFlex, Input, Button, ErrorDiv, SuccessDiv, ShadowLayer, LinkButton, Logo } from "../../pandora/styled";
import LoadingComponent from "../../components/LoadingComponent";



const Background = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url(${BackgroundImage});
    background-size: cover;
`

const Container = styled(VerticalFlex)`
    position: relative;
    width: 20%;
    min-width: 350px;
    height: 100%;
    box-sizing: border-box;
`

const LoginContainer = styled(VerticalFlex)`
    margin-top: 20%;
    width: 300px;
    height: auto;
    padding: 25px;
    border-radius: 5px;
    align-items: center;

    background: rgba(44, 50, 58, 0.6);
    animation: ${ItemContainerAnimation} 0.8s 1;
`

const Title = styled("h1")<{size: number, css?: string}>`
    margin: 0;
    text-align: center;
    font-size: ${props => props.size}px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: white;

    ${props => props.css ?? ""};
`


const SetNewPasswordView = (): React.ReactElement => {

    const REDIRECT_DELAY: number = 5000;
    const history = useHistory();

    // State
    const queryParams = qs.parse(useLocation().search)
    const { secret, userid: userID } = queryParams;
    
    const [password, setPassword] = useState<string | null>("");
    const [confirmedPassword, setConfirmedPassword] = useState<string | null>("");
    
    const [error, setError] = useState<string | null>(null)
    const [requestSuccess, setRequestSuccess] = useState<boolean>(false);

    const [submitActive, setSubmitActive] = useState<boolean>(false);

    // Set doc title
    document.title = `Återställ lösenord | ASuite`

    // Effects
    useEffect(() => {
        // If secret or userID is missing 
        // from URl query strings, redirect to login page.
        if (secret === undefined || userID === undefined) history.push(SiteRoute.Login)
    }, [])

    // Actions
    const requestReset = async () => {

        // Check credentials
        if (password === "" || confirmedPassword === "") {
            setError("Ett eller flera fält är tomma.")
            return
        }

        if (password !== confirmedPassword) {
            setError("Lösenord och bekräftelse matchar inte.")
            return
        }

        // Request password reset 
        try {
            setSubmitActive(true)
            const payload = {
                id: userID,
                secret: secret,
                password: password
            }
            const _ = await Heimdall.request(APIRequest.setNewPassword, payload)

            // Update state for password request result
            setRequestSuccess(true);
            setError(null);
            setTimeout(() => history.push(SiteRoute.Login), REDIRECT_DELAY)
        } catch (e) {
            const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
            setError(message);
        }
        setSubmitActive(false);
    }

    /**
     * Bind key press event to action.
     * @param e 
     */
    const onKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation()
            requestReset()
        }
    }

    return (
        <Background>
            <ShadowLayer>
                <Container>
                    <LoginContainer>
                        <Logo src={LogoIcon} />
                        <Title 
                            size={15}
                            css={"margin-top: 40px;"}
                        >Återställ lösenord</Title>
                        <Title 
                            size={12}
                            css={"margin-top: 10px; font-weight: normal; max-width: 80%;"}
                        >Ange ett nytt lösenord nedan och logga sedan in igen.</Title>
                        <VerticalFlex css={"margin-top: 30px;"}>
                            {error && <ErrorDiv>{error}</ErrorDiv>}
                            {requestSuccess && <SuccessDiv>{`Lösenord uppdaterat.`}</SuccessDiv>}
                            {!requestSuccess && 
                                <div>
                                    <Input 
                                        focusBorder=    {true}
                                        type=           "password"
                                        placeholder=    "Lösenord"
                                        value=          {password}
                                        onChange=       {(e) => setPassword(e.target.value)}
                                        onKeyPress=     {onKeyPress}
                                    />
                                    <Input 
                                        focusBorder=    {true}
                                        type=           "password"
                                        placeholder=    "Bekräfta lösenord"
                                        value=          {confirmedPassword}
                                        onChange=       {(e) => setConfirmedPassword(e.target.value)}
                                        onKeyPress=     {onKeyPress}
                                    />
                                </div>
                            }
                            {!submitActive && !requestSuccess &&
                                <Button 
                                    color=          {Color.green}
                                    hoverColor=     {Color.lightGreen}
                                    css=            {"margin-top: 20px; width: auto; height: 40px;"}
                                    onClick=        {requestReset}
                                >Återställ</Button>
                            }
                            {submitActive && 
                                <LoadingComponent 
                                    message=    {"Uppdaterar lösenord..."}
                                    css=        {"background-color: transparent;"}
                                />
                            }
                        </VerticalFlex>
                        <HorizontalFlex css={"justify-content: center; margin-top: 20px; width: 100%;"}>
                            <Link to={SiteRoute.Login} style={{textDecoration: "none"}}>
                                <LinkButton>Logga in</LinkButton>
                            </Link>
                            <a href={Site.webAbout} rel="noreferrer" style={{textDecoration: "none"}}>
                                <LinkButton>Om oss</LinkButton>
                            </a>
                            <a href={Site.webContact} rel="noreferrer" style={{textDecoration: "none"}}>
                                <LinkButton>Kontakt</LinkButton>
                            </a>
                        </HorizontalFlex>
                    </LoginContainer>
                </Container>
            </ShadowLayer>
        </Background>
    );
}

export default SetNewPasswordView;