import qs from "query-string";
import { useHistory } from "react-router-dom";

import { ASuiteServices, IService } from "./services";


interface RedirectArgs {
    route?: string;
    params: string;
}

export interface RedirectRequest extends RedirectArgs {
    url: string;
}


/**
 * Class handling service redirect. 
 * This class is responsible for parsing 
 * the query params sent to asuite for 
 * later redirecting to that service. 
 */
export default class ServiceRedirect {

    public static INITIAL_LOAD: boolean = true;
    private static SERVICE_KEY: string = "s"

    /**
     * Parse current location (route + query params) 
     * Retrieves query from location, check given service key 
     * exists in query (i.e. follow ASuite standard format for redirect
     * requests) and matches the passed service name with available services. 
     */
    public static getRedirect = (location): RedirectRequest => {
        const query = qs.parse(location.search)
        
        // Check query formatting 
        const qKeys: string[] = Object.keys(query);
        if (qKeys.length === 0 || !qKeys.includes(ServiceRedirect.SERVICE_KEY)) return null;

        // Retreive proper service for 
        // service query parameter. 
        const services: IService[] = ASuiteServices.all();
        for (let i = 0; i < services.length; i++) {
            const service = services[i];
            const qService = query[ServiceRedirect.SERVICE_KEY];
            if (service.identifier === qService) {
                return { 
                    url: service.link,
                    ...ServiceRedirect.getServiceQuery(query)
                }
            }
        }
        return null;
    }

    /**
     * Retrieve the inner query originally passed to 
     * the service. This preserves the service query params
     * for later redirection. 
     */
    private static getServiceQuery = (q: Record<string, unknown>): RedirectArgs => {
        delete q[ServiceRedirect.SERVICE_KEY]
        const args: string[] = [];
        let route: string = null;
        Object.keys(q).forEach(k => {
            if (k==="r") {
                route = q[k] as string;
                return;
            }
            args.push(`${k}=${q[k]}`)
        })

        return {
            route: route,
            params: args.map(String).join("&")
        }
    }
}