import React, { useState } from "react";
import styled from "styled-components";

import { IOrganisation } from "../../api/ASuiteModels";

import AtritecIcon from "../../resources/images/atritec-icon.svg";

import { PageTitle, PageIcon, VerticalFlex, HorizontalFlex, ViewContainer } from "../../pandora/styled";
import GenericPopup, { GenericPopupProps } from "../../components/GenericPopup";
import OrganisationUserItem from "../../components/organisation/OrganisationUserItem";


const FlexContainer = styled(VerticalFlex)`
    overflow: hidden;
    width: auto;
`

const OrganisationListContainer = styled(VerticalFlex)`
    overflow-y: auto;
`



const AtritecView = (): React.ReactElement => {

    // State
    const atritecOrg: IOrganisation = {id: null, name: "Atritec", services: []}
    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Set doc title
    document.title = `Atritec | ASuite`

    return (
        <ViewContainer>
            {popup && <GenericPopup 
                            title=          {popup.title}
                            message=        {popup.message}
                            color=          {popup.color}
                            setPopup=       {setPopup}
                        />
            }
            <HorizontalFlex css={"align-items: center; margin-bottom: 20px; padding-bottom: 30px; border-bottom: 2px solid #21262c;"}>
                <PageIcon icon={AtritecIcon}/>
                <PageTitle>Atritec</PageTitle>
            </HorizontalFlex>
            <FlexContainer>
                <OrganisationListContainer>
                    <OrganisationUserItem 
                        isAtritec=      {true}
                        organisation=   {atritecOrg}
                        focused=        {true}
                        setPopup=       {setPopup}
                    />
                </OrganisationListContainer>
            </FlexContainer>
        </ViewContainer>
    )
}

export default AtritecView;