
export enum HTTPRequestMethod {
    POST = "POST",
    PUT = "PUT",
    GET = "GET",
    DELETE = "DELETE"
}

enum APIRoute {
    User = "user",
    Employee = "employee",
    Auth = "auth",
    Admin = "admin",
    Organisation = "organisation",
    Service = "service",
    Notifications = "notifications"
}


/**
 * Request protocol defining keys 
 * expected and required for performing an API 
 * request. Some optional keys, like content type and headers
 * defined optional key-value pairs inserted into the request
 * header. 
 */
export interface Request {
    method: HTTPRequestMethod
    keys: string[]
    public?: boolean
    contentType?: string 
    headers?: Record<string, any>
    opts?: Record<string, any>
    route: (...args: any[]) => string
}

/**
 * 
 */
export default class APIRequest {

    /**
     * Construct generic API route. 
     */
    private static makeURL = (...args: Array<string | number>): string => "/" + args.map(String).join("/");

    // USER:

    // Get the logged in user
    static auth: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.User, "current")
    }

    // Get the current user's organisation
    static getCurrentOrganisation: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.User, APIRoute.Organisation)
    }

    // Get a list of Users for the logged in user's organisation
    static getCurrentOrganisationUsers: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.User, APIRoute.Organisation, "userlist")
    }

    // Update a users's password
    static updatePassword: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["oldpassword", "newpassword"],
        contentType: "application/x-www-form-urlencoded",
        route: () => APIRequest.makeURL(APIRoute.User, "setpassword")
    }

    // Edit the currently logged in user. ONLY name and phone may be edited
    static updateCurrentUser: Request = {
        method: HTTPRequestMethod.PUT,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.User, "current")
    }

    // Get all notifications for a user. 
    // Omitted filters will include all notifications service: show only specified service, 
    // fromtime: Show only notifications newer than timestamp, seen: show only notifications with the specified state
    static getNotifications: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.User, APIRoute.Notifications)
    }

    // Marks all unseen notifications as seen for a user
    static markAllNotificationsSeen: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.User, APIRoute.Notifications)
    }

    // Marks all unseen notifications as seen for a user
    static markNotificationSeen: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id"],
        route: (notificationID: number) => APIRequest.makeURL(APIRoute.User, APIRoute.Notifications, notificationID)
    }

    // Marks all unseen notifications as seen for a user
    static markNotificationUnseen: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (notificationID: number) => APIRequest.makeURL(APIRoute.User, APIRoute.Notifications, notificationID)
    }



    // ADMINISTRATION:

    // List pending user accounts which have not yet been activated
    static getPendingUsers: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.Admin, APIRoute.User, "pending")
    }

    // Delete a pending user account
    static deletePendingUser: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (userID: number) => APIRequest.makeURL(APIRoute.Admin, APIRoute.User, "pending", userID)
    }

    // Get a User by their UUID. Users may only access other users in their ORG
    static getUser: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (userID: number) => APIRequest.makeURL(APIRoute.Admin, APIRoute.User, userID)
    }

    // Edit any user. A customer may use PUT /user/me
    static updateUser: Request = {
        method: HTTPRequestMethod.PUT,
        keys: ["id"],
        route: (userID: number) => APIRequest.makeURL(APIRoute.Admin, APIRoute.User, userID)
    }

    // List pending employee accounts which have not yet been activated
    static getAllEmployees: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.Admin, APIRoute.Employee, "all")
    }

    static getPendingEmployees: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.Admin, APIRoute.Employee, "pending")
    }

    // Create a new Employee account
    static inviteEmployee: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["name", "email"],
        route: () => APIRequest.makeURL(APIRoute.Admin, APIRoute.Employee)
    }

    // Create a new organisation
    static createOrganisation: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["name"],
        route: () => APIRequest.makeURL(APIRoute.Admin, APIRoute.Organisation)
    }

    // List all organisations. 
    static getOrganisations: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.Admin, APIRoute.Organisation, "all")
    }

    // Create a new User.
    static inviteUser: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id", "email", "name"],
        route: (orgID: number) => APIRequest.makeURL(APIRoute.Admin, APIRoute.Organisation, orgID, APIRoute.User)
    }
    
    // Get all users for an organisation, by organisation UUID
    static getUsersForOrganistion: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (orgID: number) => APIRequest.makeURL(APIRoute.Admin, APIRoute.Organisation, orgID, APIRoute.User, "all")
    }

    // Get all users for an organisation, by organisation UUID
    static getPendingUsersForOrganistion: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (orgID: number) => APIRequest.makeURL(APIRoute.Admin, APIRoute.Organisation, orgID, "pending")
    }

    // Get an organisation object by UUID.
    static getOrganisation: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (orgID: number) => APIRequest.makeURL(APIRoute.Admin, APIRoute.Organisation, orgID)
    }

    // Edit organisation.
    static updateOrganisation: Request = {
        method: HTTPRequestMethod.PUT,
        keys: ["id", "name"],
        route: (orgID: number) => APIRequest.makeURL(APIRoute.Admin, APIRoute.Organisation, orgID)
    }

    // Add a new service access to an organisation. Returns the full org object
    static addService: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id", "service"],
        route: (orgID: number) => APIRequest.makeURL(APIRoute.Admin, APIRoute.Organisation, orgID, APIRoute.Service)
    }

    // Remove service access from an organisation. Returns the full org object
    static removeService: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id", "service"],
        route: (orgID: number) => APIRequest.makeURL(APIRoute.Admin, APIRoute.Organisation, orgID, APIRoute.Service)
    }


    // AUTH:

    // Log in user
    static login: Request = {
        method: HTTPRequestMethod.POST,
        public: true,
        keys: ["username", "password"],
        contentType: "application/x-www-form-urlencoded",
        headers: {
            "Accept": "application/json"
        },
        route: () => APIRequest.makeURL(APIRoute.Auth, "token")
    }

    // Confirm a user creation by supplying a password
    static confirmRegistration: Request = {
        method: HTTPRequestMethod.POST,
        public: true,
        keys: ["id", "secret", "password"],
        route: (userID: string, secret: string) => APIRequest.makeURL(APIRoute.Auth, "confirm", userID, secret)
    }

    // Request password reset for user
    static requestPasswordReset: Request = {
        method: HTTPRequestMethod.POST,
        public: true,
        keys: ["email"],
        route: () => APIRequest.makeURL(APIRoute.Auth, "reset")
    }

    // Set a new password. Supply the secret sent with email
    static setNewPassword: Request = {
        method: HTTPRequestMethod.POST,
        public: true,
        keys: ["id", "secret", "password"],
        route: (userID: string, secret: string) => APIRequest.makeURL(APIRoute.Auth, "setpassword", userID, secret)
    }
}

