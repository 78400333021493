import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { ASuiteServices, IService } from "../../site/services";
import Heimdall from "../../api/Heimdall";
import APIRequest from "../../api/APIRequest";

import { IOrganisation, Service } from "../../api/ASuiteModels";

import { GenericPopupProps } from "../../components/GenericPopup";
import { VerticalFlex, HorizontalFlex, SaveButton, Input} from "../../pandora/styled";
import { FadeAnimation } from "../../pandora/animations";

import Color from "../../resources/colors";
import ArrowIcon from "../../resources/images/arrow-icon.svg";



const ContentContainer = styled(VerticalFlex)<{focused: boolean}>`
    position: relative;
    height: auto;
    width: auto;
    padding: 20px;
    margin-bottom: 15px;

    border-radius: 5px;
    border-left: 3px solid transparent;
    background-color: ${Color.darkGrey};
    overflow: visible;

    cursor: pointer;
    user-select: none;

    transition: 0.3s ease;

    ${props => !props.focused ? `    
        &: hover {
            border-left: 3px solid ${Color.green};
        }
    ` 
    : 
    `
        border-left: 3px solid ${Color.green};
    `
    }
`

const Arrow = styled("div")<{rotate: boolean}>`
    position: absolute;
    width: 15px;
    height: 15px;

    top: 35px;
    right: 25px;

    transition: 0.3s ease;
    transform: rotateX(${props => props.rotate ? 180 : 0}deg);

    background-image: url(${ArrowIcon});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

const Title = styled("h1")<{size: number, css?: string}>`
    margin: 0;
    text-align: left;
    font-size: ${props => props.size}px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: white;

    ${props => props.css ?? ""};
`

const ServiceList = styled(VerticalFlex)`
    position: relative;

    min-height: 70px;
    height: fit-content;
    margin-top: 15px;
    padding: 15px;
    width: auto;

    cursor: auto;

    background-color: ${Color.lightGrey};
    border-radius: 5px;
`

const AddServiceListContainer = styled(HorizontalFlex)`
    margin-top: 10px;
    padding: 15px;

    cursor: auto;

    border-radius: 5px;
    background-color: ${Color.lightGrey};

    animation: ${FadeAnimation} 0.3s 1;
`

const ServiceListItem = styled("div")<{icon: string, color: string, css?: string}>`
    position: relative;
    max-width: 150px;
    height: 20px;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 10px;    

    border-radius: 5px;

    cursor: pointer;
    user-select: none;
    z-index: 10;
    
    font-size: 10px;
    font-weight: bold;
    text-align: left;
    line-height: 20px;
    text-indent: 25px;
    color: white;

    background-image: url(${props => props.icon});
    background-size: 15px 15px;
    background-position: 10px 50%;
    background-repeat: no-repeat;

    background-color: ${props => props.color};

    transition: 0.3s ease;
    animation: ${FadeAnimation} 0.3s 1;

    &: hover {  
        &: before {
            display: block;
        }
    }

    &: before {
        position: absolute;
        display: none;
        
        z-index: 1;

        content: "";

        height: 100%;
        width: 100%;
        
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.2);
    }

    ${props => props.css ?? ""}
`

const OrgNameInput = styled(Input)`
    font-size: 18px; 
    font-weight: bold; 

    height: 35px;
    margin-top: 5px;
    padding-left: 0; 
    max-width: 50%;

    &: hover, &: focus {
        border-bottom: 3px solid ${Color.green};
        margin-top: 10px;
        margin-bottom: 10px;
    }
`


interface AddServiceListProps {
    services: IService[]
    addService: (e: React.MouseEvent, service: IService) => void
}

const AddServiceList = (props: AddServiceListProps) => {
    return (
        <AddServiceListContainer>
            <VerticalFlex>
                <Title size={12}>Lägg till service</Title>
                <HorizontalFlex css={"margin-top: 15px;"}>
                    {props.services.map(s => 
                            <ServiceListItem 
                                key=        {s.name}
                                icon=       {s.icon}
                                color=      {s.color}
                                onClick=    {(e) => props.addService(e, s)}
                            >{s.name}</ServiceListItem>
                        )
                    }
                </HorizontalFlex>
            </VerticalFlex>
        </AddServiceListContainer>
    )
}



interface OrganisationAdminItemProps {
    organisation: IOrganisation;
    focused: boolean;
    setFocusOrg: (o: IOrganisation) => void;
    setPopup: (popup: GenericPopupProps) => void;
}

const OrganisationAdminItem = (props: OrganisationAdminItemProps): React.ReactElement => {


    // State
    const { organisation, focused } = props;
    const _services: IService[] = organisation.services.map(s => ASuiteServices.get(Service[s]))

    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [orgName, setOrgName] = useState<string>(organisation.name);
    const [services, setServices] = useState<IService[]>(_services);
    const [remainingServices, setRemainingServices] = useState<IService[]>([]);


    // Effects

    /**
     * Retrieve services not registered for organisation. 
     */
    useEffect(() => {
        const _remainingServices = ASuiteServices.all().filter(s => !services.includes(s));
        setRemainingServices(_remainingServices);
    }, [services])


    // Actions

    /**
     * Save user made changes. 
     * @param e 
     * @param showPopup Flag to show popup on save successful. 
     */
    const saveChanges = async (e, showPopup: boolean = true) => { 
        e.stopPropagation()
        try {
            // Request organisation update
            if (orgName === "") throw new Error("Tomma värden ej tillåtna.")
            const payload = {
                id: organisation.id,
                name: orgName
            }
            const _ = await Heimdall.request(APIRequest.updateOrganisation, payload);
            setHasChanges(false);

            if (showPopup) {
                props.setPopup({
                    title: "Sparat!",
                    message: "Ändringarna har sparats.",
                    color: Color.green,
                    setPopup: props.setPopup
                }) 
            }
        } catch (e) {
            const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
            props.setPopup({
                title: "Ett fel uppstod.",
                message: message,
                color: Color.red,
                setPopup: props.setPopup
            }) 
        }
    }

    /**
     * Handle organisation name edit. 
     * @param e 
     */
    const editOrgName = (e) => { 
        const { value } = e.target;
        if (value.length <= 50) {
            setOrgName(value);
            if (!hasChanges) setHasChanges(true);
        }
    }

    /**
     * Register service for organisation. 
     * @param e event
     * @param service service to register
     */
    const addService = async (e, service: IService) => {
        e.stopPropagation();
        try {
            // Request service addition
            const payload = {
                id: organisation.id,
                service: service.type
            }
            const _: IOrganisation = await Heimdall.request<IOrganisation>(APIRequest.addService, payload) as IOrganisation

            // Add locally
            setServices([...services, service])
        } catch (e) {
            const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
            props.setPopup({
                title: "Ett fel uppstod.",
                message: message,
                color: Color.red,
                setPopup: props.setPopup
            })
        }
    }

    /**
     * Remove service for organisation 
     * @param e event
     * @param service service to remove.  
     */
    const removeService = async (e, service: IService) => {
        e.stopPropagation();
        try {
            // Request service removal
            const payload = {
                id: organisation.id,
                service: service.type
            }
            const _ = await Heimdall.request<IOrganisation>(APIRequest.removeService, payload) as IOrganisation
            
            // Remove service locally
            const _services = [...services]
            _services.splice(services.indexOf(service), 1)
            setServices([..._services])
        } catch (e) {
            const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
            props.setPopup({
                title: "Ett fel uppstod.",
                message: message,
                color: Color.red,
                setPopup: props.setPopup
            })
        }
    }

    /**
     * Bind action to specific key press.
     */
    const onKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation()
            saveChanges(e)
        }
    }


    return (
        <ContentContainer 
            focused=    {focused}
            onClick=    {() => props.setFocusOrg(focused ? null : organisation)}
        >
            <Title 
                size={12} 
                css={"font-weight: normal;"}
            >{organisation.id}</Title>
            {hasChanges && 
                <SaveButton 
                    onClick={(e) => saveChanges(e, true)}
                >Spara</SaveButton>
            } 
            <Arrow rotate={focused}/>
            {!focused && 
                <Title 
                    size=   {18}
                    css=    {"margin-top: 10px;"}
                >{orgName}</Title>
            }
            {focused && 
                <OrgNameInput 
                    focusBorder=    {false}
                    type=           "text"
                    placeholder=    "Namn"
                    value=          {orgName}
                    onChange=       {editOrgName}
                    onKeyPress=     {onKeyPress}
                    onClick=        {(e) => e.stopPropagation()}
                    readOnly=       {!focused}
                />
            }
            {focused && 
                <ServiceList>
                    <Title 
                        size=   {14}
                        css=    {"margin-bottom: 10px;"}
                    >Services</Title>
                    <HorizontalFlex>
                        {services.length !== 0 && services.map((s: IService) => 
                                <ServiceListItem 
                                    key=        {s.name}
                                    icon=       {s.icon}
                                    color=      {s.color}
                                    onClick=    {(e) => removeService(e, s)}
                                    css=        {"margin-top: 5px;"}
                                >{s.name}</ServiceListItem>
                            )
                        }
                    </HorizontalFlex>
                    {services.length === 0 && 
                        <Title
                            size=   {12}
                            css=    {"margin-bottom: 10px; font-weight: normal;"}
                        >Inga services</Title>
                    }
                </ServiceList>
            }
            {focused && remainingServices.length !== 0 && 
                <AddServiceList 
                    services=   {remainingServices} 
                    addService= {addService}
                />
            }
        </ContentContainer>
    )
}


export default OrganisationAdminItem;