import React, { useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";

import Auth from "../../site/auth";
import { Site, SiteRoute } from "../../routes/site";

import Color from "../../resources/colors";
import LogoIcon from "../../resources/images/asuite-logo.png";
import BackgroundImage from "../../resources/images/background-image-1.jpg";

import { ItemContainerAnimation } from "../../pandora/animations";
import { HorizontalFlex, VerticalFlex, Input, Button, ShadowLayer, LinkButton, ErrorDiv, Logo } from "../../pandora/styled";
import LoadingComponent from "../../components/LoadingComponent";


const Background = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url(${BackgroundImage});
    background-size: cover;
`

const Container = styled(VerticalFlex)`
    position: relative;
    width: 20%;
    min-width: 350px;
    height: 100%;
    box-sizing: border-box;
`

const LoginContainer = styled(VerticalFlex)`
    margin-top: 20%;
    width: 300px;
    height: auto;
    padding: 25px;
    border-radius: 5px;
    align-items: center;

    background: rgba(44, 50, 58, 0.6);
    animation: ${ItemContainerAnimation} 0.8s 1;
`


interface LoginViewProps {
    setAuth: (value: boolean) => void
}

const LoginView = (props: LoginViewProps): React.ReactElement => {

    const history = useHistory()

    // State
    const [email, setEmail] = useState<string | null>("");
    const [password, setPassword] = useState<string | null>("");
    const [error, setError] = useState<string | null>(null)

    const [loginActive, setLoginActive] = useState<boolean>(false);

    // Set doc title
    document.title = `Logga in | ASuite`

    // Actions
    const login = async () => {

        // Check credentials
        if (email === "" || password === "") {
            setError("Ett eller flera fält är tomma.")
            return
        }

        // Attempt login 
        try {
            setLoginActive(true)
            const credentials = {username: email, password: password}
            const params = new URLSearchParams(credentials)
            const didLogIn = await Auth.login(params)

            // Update state for log in result 
            if (didLogIn) {
                props.setAuth(didLogIn)
                history.push(SiteRoute.Dashboard)            
            }
        } catch (e) {
            const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
            setError(message)
            setLoginActive(false)
        }
    }

    const onKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation()
            login()
        }
    }

    return (
        <Background>
            <ShadowLayer>
                <Container>
                    <LoginContainer>
                        <Logo src={LogoIcon} />
                        <VerticalFlex css={"margin-top: 40px;"}>
                            {error && <ErrorDiv>{error}</ErrorDiv>}
                            <Input 
                                focusBorder=    {true}
                                type=           "email"
                                placeholder=    "Email"
                                value=          {email}
                                onChange=       {(e) => setEmail(e.target.value)}
                                onKeyPress=     {onKeyPress}
                            />
                            <Input 
                                focusBorder=    {true}
                                type=           "password"
                                placeholder=    "Lösenord"
                                value=          {password}
                                onChange=       {(e) => setPassword(e.target.value)}
                                onKeyPress=     {onKeyPress}
                            />
                            {!loginActive ? 
                                <Button 
                                    color=          {Color.green}
                                    hoverColor=     {Color.lightGreen}
                                    css=            {"margin-top: 20px; width: auto; height: 40px;"}
                                    onClick=        {login}
                                >Logga in</Button>
                                :
                                <LoadingComponent
                                    message=    {""} 
                                    css=        {"background-color: transparent;"}
                                />
                            }
                        </VerticalFlex>
                        <HorizontalFlex css={"justify-content: center; margin-top: 20px; width: 100%;"}>
                            <Link to={SiteRoute.RequestPasswordReset} style={{textDecoration: "none"}}>
                                <LinkButton>Glömt lösenord</LinkButton>
                            </Link>
                            <a href={Site.webAbout} rel="noreferrer" style={{textDecoration: "none"}}>
                                <LinkButton>Om oss</LinkButton>
                            </a>
                            <a href={Site.webContact} rel="noreferrer" style={{textDecoration: "none"}}>
                                <LinkButton>Kontakt</LinkButton>
                            </a>
                        </HorizontalFlex>
                    </LoginContainer>
                </Container>
            </ShadowLayer>
        </Background>
    );
}

export default LoginView;