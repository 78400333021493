import { URLSearchParams } from "url";

import { IUser, Role } from "../api/ASuiteModels";
import Heimdall from "../api/Heimdall";
import { CookieHandler, ASuiteCookies } from "./cookies";
import APIRequest from "api/APIRequest";


export default class Auth {

    /**
     * Current logged in user instance.
     */
    public static currentUser: IUser;

    /**
     * Retrieve token and set static token
     * on API caller {@link Heimdall}. Without 
     * calling the setToken(token:) on Heimdall, 
     * API calls won't work.
     */
    public static init = (): boolean => {
        const token = Auth.getToken()
        Auth.setToken(token)
        return token !== undefined
    }

    /**
     * Verify session with token 
     * is authenticated. Set current user if
     * token is valid.
     */
    public static verify = async (): Promise<boolean> => {
        try {
            const res: IUser = await Heimdall.authenticate()
            if (res !== null) Auth.setUser(res)
            return res !== null;
        } catch (e) {
            // Current token is not valid
            Auth.setToken(undefined)
            Auth.setUser(undefined)
            return false
        }
    }

    /**
     * Log in using credentials.
     * Firstly, request token for credentials retrieving session token.
     * Secondly, set token.
     * Thirdly, request user instance with new token set. 
     * Lastly, set retrieved user.
     * @return Login successful. 
     */
    public static login = async (credentials: URLSearchParams): Promise<boolean> => {
        const res = await Heimdall.request(APIRequest.login, credentials)
        Auth.setToken(res["access_token"])
        const user: IUser = await Heimdall.authenticate()
        if (user !== null) Auth.setUser(user)
        return user !== null
    }

    /**
     * Remove login token cookie and 
     * redirect to ASuite. 
     */
    public static logout = (): void => {
        Auth.removeToken()
        Heimdall.setToken(undefined);
    }

    /**
     * Set currently logged in user.
     * @param user 
     */
    public static setUser = (user: IUser): void => { Auth.currentUser = user; }

    /**
     * Retrive authentication token from cookies. 
     */
    public static getToken = (): string | undefined => {
        return CookieHandler.get(ASuiteCookies.Auth)
    }

    /**
     * Set authentication cookie.
     */
    private static setToken = (token: string): void => {
        if (token !== undefined) {
            Heimdall.setToken(token)
            CookieHandler.set(ASuiteCookies.Auth, token)
        } else {
            Auth.removeToken()
        }
    }

    /**
     * Remove authentication cookie.
     */
    private static removeToken = (): void => {
        CookieHandler.remove(ASuiteCookies.Auth)
    }

    public static isAdmin = (): boolean => {
        if (Auth.currentUser === undefined) 
            throw new Error("Attempted use of authenticated user, but user not logged in.")
        return Auth.currentUser.role === Role.Admin;
    }

    public static isEmployee = (): boolean => {
        if (Auth.currentUser === undefined) 
            throw new Error("Attempted use of authenticated user, but user not logged in.")
        return Auth.currentUser.isEmployee;
    }
}
