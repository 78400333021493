import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation, useHistory } from "react-router-dom";
import qs from "query-string";

import Heimdall from "../../api/Heimdall";
import APIRequest from "../../api/APIRequest";
import { Site, SiteRoute } from "../../routes/site";

import Color from "../../resources/colors";
import LogoIcon from "../../resources/images/asuite-logo.png";
import BackgroundImage from "../../resources/images/background-image-3.jpg";

import { ItemContainerAnimation } from "../../pandora/animations";
import { HorizontalFlex, VerticalFlex, Input, Button, ErrorDiv, SuccessDiv, ShadowLayer, LinkButton, Logo } from "../../pandora/styled";
import LoadingComponent from "../../components/LoadingComponent";



const Background = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url(${BackgroundImage});
    background-size: cover;
`

const Container = styled(VerticalFlex)`
    position: relative;
    width: 20%;
    min-width: 350px;
    height: 100%;
    box-sizing: border-box;
`

const LoginContainer = styled(VerticalFlex)`
    margin-top: 20%;
    width: 300px;
    height: auto;
    padding: 25px;
    border-radius: 5px;
    align-items: center;

    background: rgba(44, 50, 58, 0.6);
    animation: ${ItemContainerAnimation} 0.8s 1;
`

const Title = styled("h1")<{size: number, css?: string}>`
    margin: 0;
    text-align: center;
    font-size: ${props => props.size}px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: white;

    ${props => props.css ?? ""};
`





const ConfirmRegistrationView = (): React.ReactElement => {

    const REDIRECT_DELAY: number = 5000;
    const history = useHistory();

    // State

    // Parse query params to retrieve user ID
    // and secret stored in URL for user verification 
    // in API calls. 
    const queryParams = qs.parse(useLocation().search)
    const { secret, userid: userID, username } = queryParams;
    
    const [password, setPassword] = useState<string | null>("");
    const [confirmedPassword, setConfirmedPassword] = useState<string | null>("");
    
    const [error, setError] = useState<string | null>(null)
    const [requestSuccess, setRequestSuccess] = useState<boolean>(false);

    const [submitActive, setSubmitActive] = useState<boolean>(false);

    // Effects
    useEffect(() => {
        // If secret, username or userID is missing 
        // from URl query strings, redirect to login page.
        if (secret === undefined || userID === undefined || username === undefined) 
            history.push(SiteRoute.Login)

        // Set doc title
        document.title = `Bekräfta registrering | ASuite`
    }, [])

    // Actions
    const requestConfirmation = async () => {

        // Check credentials
        if (password === "" || confirmedPassword === "") {
            setError("Ett eller flera fält är tomma.")
            return
        }

        if (password !== confirmedPassword) {
            setError("Lösenord och bekräftelse matchar inte.")
            return
        }

        // Request user registration confirmation
        try {
            setSubmitActive(true)
            const payload = {
                id: userID,
                secret: secret,
                password: password
            }
            const _ = await Heimdall.request(APIRequest.confirmRegistration, payload)

            // Update state for confirmation request result
            setRequestSuccess(true);
            setError(null);
            setTimeout(() => history.push(SiteRoute.Login), REDIRECT_DELAY)
        } catch (e) {
            const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
            setError(message);
        }
        setSubmitActive(false);
    }

    /**
     * Bind key press event to action. 
     * @param e 
     */
    const onKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation()
            requestConfirmation()
        }
    }

    return (
        <Background>
            <ShadowLayer>
                <Container>
                    <LoginContainer>
                        <Logo src={LogoIcon} />
                        <Title 
                            size={15}
                            css={"margin-top: 40px;"}
                        >{`Skapa konto för ${username}`}</Title>
                        <Title 
                            size={12}
                            css={"margin-top: 10px; font-weight: normal; max-width: 80%;"}
                        >Ange ett lösenord för ditt ASuite konto nedan och logga sedan in.</Title>
                        <VerticalFlex css={"margin-top: 30px;"}>
                            {error && <ErrorDiv>{error}</ErrorDiv>}
                            {requestSuccess && <SuccessDiv>{`Konto skapat`}</SuccessDiv>}
                            {!requestSuccess && 
                                <div>
                                    <Input 
                                        focusBorder=    {true}
                                        type=           "password"
                                        placeholder=    "Lösenord"
                                        value=          {password}
                                        onChange=       {(e) => setPassword(e.target.value)}
                                        onKeyPress=     {onKeyPress}
                                    />
                                    <Input 
                                        focusBorder=    {true}
                                        type=           "password"
                                        placeholder=    "Bekräfta lösenord"
                                        value=          {confirmedPassword}
                                        onChange=       {(e) => setConfirmedPassword(e.target.value)}
                                        onKeyPress=     {onKeyPress}
                                    />
                                </div>
                            }
                            {!submitActive && !requestSuccess &&
                                <Button 
                                    color=          {Color.green}
                                    hoverColor=     {Color.lightGreen}
                                    css=            {"margin-top: 20px; width: auto; height: 40px;"}
                                    onClick=        {requestConfirmation}
                                >Skapa konto</Button>
                            }
                            {submitActive && 
                                <LoadingComponent 
                                    message=    {"Skapar konto..."}
                                    css=        {"background-color: transparent;"}
                                />
                            }
                        </VerticalFlex>
                        <HorizontalFlex css={"justify-content: center; margin-top: 20px; width: 100%;"}>
                            <Link to={SiteRoute.Login} style={{textDecoration: "none"}}>
                                <LinkButton>Logga in</LinkButton>
                            </Link>
                            <a href={Site.webAbout} rel="noreferrer" style={{textDecoration: "none"}}>
                                <LinkButton>Om oss</LinkButton>
                            </a>
                            <a href={Site.webContact} rel="noreferrer" style={{textDecoration: "none"}}>
                                <LinkButton>Kontakt</LinkButton>
                            </a>
                        </HorizontalFlex>
                    </LoginContainer>
                </Container>
            </ShadowLayer>
        </Background>
    );
}

export default ConfirmRegistrationView;