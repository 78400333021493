import React from "react";
import styled from "styled-components";

import Auth from "../site/auth";
import { IUser } from "../api/ASuiteModels";

import DeleteIcon from "../resources/images/delete-icon.png";
import ProfileIcon from "../resources/images/profile-icon.svg";
import EmailIcon from "../resources/images/email-icon.png";
import SettingsIcon from "../resources/images/settings-icon.png";

import { Input } from "../pandora/styled";
import { FadeAnimation } from "../pandora/animations";
import Color from "../resources/colors";

import RoleDropdown from "./RoleDropdown";


const HeaderSpan = styled("span")<{icon?: string, editable: boolean}>`
    display: flex;
    align-items: center;

    color: white;
    font-weight: bold;
    font-size: 12px;
    line-height: 25px;
    text-indent: 30px;

    background-color: ${Color.lightGrey};
    height: 25px;
    border-radius: 5px;    
    padding: 5px;

    margin-bottom: ${props => props.editable ? 10 : 0}px;

    ${props => props.icon !== undefined && `
        background-image: url(${props.icon});
        background-repeat: no-repeat;
        background-size: 15px 15px;
        background-position: 10px 50%;    
    `}

    ${props => props.icon === undefined && `
        padding-left: 0;
        text-indent: 0;
    `}
`

const ContainerGrid = styled("div")<{cols: number, editable: boolean, deletable: boolean, css?: string}>`
    position: relative;
    display: grid;
    margin-top: 20px;

    grid-template-columns: repeat(${props => props.cols}, 1fr) ${props => props.deletable ? "35px" : ""};
    column-gap: 10px;
    row-gap: ${props => props.editable ? "5px" : "15px"};

    transition: 0.3s ease;

    span { 
        display: flex;
        align-items: center;

        text-align: left;
        color: white;
        font-size: 11px;
        font-family: Montserrat, sans-serif;
        padding-left: 10px;
    }

    @media only screen and (max-width: 750px) {
        grid-template-columns: repeat(1, 1fr);
    }

    animation: ${FadeAnimation} 0.5s 1; 

    ${props => props.css ?? ""}
`

const DeleteButton = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 5px;

    background-color: ${Color.red};
    background-image: url(${DeleteIcon});
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: center;

    transition: 0.3s ease;

    &: hover {
        background-color: ${Color.lightRed};
    }
`

const UserDataInput = styled(Input)`
    font-size: 11px; 
    height: 30px;
    padding: 0;
    text-indent: 10px;

    &: hover {
        background-color: ${Color.lightGrey};
    }

    &: focus {
        background-color: ${Color.lightGrey};
    }
`


const PlaceholderMap = {
    "name": "Namn",
    "email": "Email"
}


interface UserListProps {
    users: IUser[];
    isEmployeeTable: boolean;
    includeID: boolean;
    deletable: boolean;
    editable: boolean;
    deleteUser?: (e: React.MouseEvent, user: IUser) => void
    editUser?: (user: IUser, update: Record<string, string>) => void;
    css?: string
}

/**
 * Generic user list component rendering
 * a grid with user information. Configure 
 * user data list appearance and data inclusion 
 * with props. 
 * @param props 
 */
const UserList = (props: UserListProps): React.ReactElement => {
    
    // Get state
    const { 
        users, 
        isEmployeeTable, 
        includeID,
        deletable, 
        editable, 
        css,
        deleteUser, 
        editUser
    } = props;

    // Property existence assertions
    if (deletable && deleteUser === undefined) 
        throw new Error("User list entry deletable but no deletion method passed.")
        
    if (editable && editUser === undefined) 
        throw new Error("User list entry editable but no editing method passed.")

    let cols = isEmployeeTable ? 3 : 2;
    if (includeID) cols++;

    // Construct row element
    const getElement = (user: IUser, prop: string): JSX.Element => {
        const element: JSX.Element = !editable ? 
            <span key={user[prop]}>{user[prop]}</span>
            :
            <UserDataInput 
                key=            {`${user.id}${prop}tf`}     // This creates a unique key for this element instance
                focusBorder=    {false}
                type=           "text"
                placeholder=    {PlaceholderMap[prop]}
                value=          {user[prop]}
                onChange=       {(e) => editUser(user, {[prop]: e.target.value})}
                onClick=        {(e) => e.stopPropagation()}
                readOnly=       {prop === "id"}
            />
        return element
    }

    // Setup row elements with  
    // user data and actions.
    const rows: (JSX.Element)[][] = [];
    users.forEach((user: IUser) => {
        if (user.id === Auth.currentUser.id && !editable) return; // Skip current user in listings
        const row = [getElement(user, "name"), getElement(user, "email")]

        // Add optional elements for editing and deletion
        if (includeID) row.push(getElement(user, "id"))
        if (isEmployeeTable) {
            if (editable) {
                row.push(
                    <RoleDropdown 
                        key={`${user.id}rd`} 
                        currentRole={user.role} 
                        user={user} 
                        editUser={editUser} 
                    />
                );
            } else {
                row.push(getElement(user, "role"))
            }
        }
        if (deletable) row.push(
            <DeleteButton 
                key={`${user.id}db`} 
                onClick={(e) => props.deleteUser(e, user)}
            />
        )
        rows.push(row)
    })

    return (
        <ContainerGrid 
            cols=       {cols} 
            deletable=  {deletable} 
            editable=   {editable}
            css=        {css}
        >
            <HeaderSpan editable icon={ProfileIcon}>Namn</HeaderSpan>
            <HeaderSpan editable icon={EmailIcon}>Email</HeaderSpan>
            {includeID && <HeaderSpan editable>UUID</HeaderSpan>}
            {isEmployeeTable && <HeaderSpan editable icon={SettingsIcon}>Roll</HeaderSpan>}
            {deletable && <HeaderSpan editable/>}
            {rows.map(row => row.map(d => d))}
        </ContainerGrid>
    )
}

export default UserList;