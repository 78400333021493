import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Auth from "../../site/auth";
import  { SiteRoute } from "../../routes/site";

import Heimdall from "../../api/Heimdall";
import APIRequest from "../../api/APIRequest";
import { IUser, IOrganisation } from "../../api/ASuiteModels";

import IDIcon from "../../resources/images/id-icon.svg";
import ProfileIcon from "../../resources/images/profile-icon.svg";
import EmailIcon from "../../resources/images/email-icon.png";
import PhoneIcon from "../../resources/images/phone-icon.svg";

import Color from "../../resources/colors";

import { PageTitle, PageIcon, VerticalFlex, HorizontalFlex, Button, ViewContainer } from "../../pandora/styled";
import GenericPopup, { GenericPopupProps } from "../../components/GenericPopup";
import LoadingComponent from "../../components/LoadingComponent";
import UserList from "../../components/UserList";


const ContentContainer = styled(VerticalFlex)`
    position: relative;
    height: auto;
    width: 100%;
    padding: 30px;

    border-radius: 5px;
    background-color: ${Color.darkGrey};
`

const FlexContainer = styled(HorizontalFlex)`
    overflow: hidden;
    @media only screen and (max-width: 1000px) {
        flex-direction: column;

        ${ContentContainer} {
            width: auto;
            min-width: fit-content;
            margin-bottom: 15px;
        }
    }
`

const Title = styled("h1")<{size: number, css?: string}>`
    margin: 0;
    margin-top: 5px;
    text-align: left;
    font-size: ${props => props.size}px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: white;
    word-break: break-all;

    ${props => props.css ?? ""};
`

const Icon = styled.img`
    width: 25px;
    height: 25px;
    margin: 0;
    margin-right: 20px;
`

const ActionButton = styled(Button)<{css?: string}>`
    width: auto;
    height: 40px;
    
    margin-top: 40px;

    line-height: 40px;
    font-size: 12px;

    ${props => props.css ?? ""}
`



const ProfileView = (): React.ReactElement => {

    const history = useHistory()

    // State
    const isEmployee = Auth.isEmployee()
    const [user, setUser] = useState<IUser | null>(null);
    const [organisation, setOrganisation] = useState<IOrganisation | null>(null);
    const [orgMembers, setOrgMembers] = useState<IUser[] | null>(null);

    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Set doc title
    document.title = `Min Profil | ASuite`

    // Effects
    useEffect(() => {

        /**
         * Retrieve profile data for current user. 
         */
        const getProfileData = async () => { 
            try {
                // Define expected data to be retrieved
                let _org: IOrganisation;
                let _members: IUser[];

                // Fetch data 
                const _user: IUser = await Heimdall.request(APIRequest.auth) as IUser;
                if (!isEmployee) {
                    _org = await Heimdall.request(APIRequest.getCurrentOrganisation) as IOrganisation
                    _members = await Heimdall.request(APIRequest.getCurrentOrganisationUsers) as IUser[]
                } else {
                    _org = {id: null, name: "Atritec", services: []}
                    _members = await Heimdall.request(APIRequest.getAllEmployees) as IUser[]
                }
                
                // Set data
                setUser(_user);
                setOrganisation(_org);
                setOrgMembers(_members);

                // Update current Auth handler user instance 
                // with updated state from API. 
                Auth.setUser(_user);
            } catch (e) {
                const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
                setPopup({
                    title: "Ett fel uppstod.",
                    message: message,
                    color: Color.red,
                    setPopup: setPopup
                }) 
            }
        }

        getProfileData()
    }, [])


    // Actions

    // Perform logout action. 
    const logout = () => { 
        Auth.logout();
        window.location.reload();
    }

    // Push update profile view route. 
    const navUpdateProfile = () => { history.push(SiteRoute.UpdateProfile) }
    

    return (
        <ViewContainer>
            {popup && <GenericPopup 
                            title=          {popup.title}
                            message=        {popup.message}
                            color=          {popup.color}
                            setPopup=       {setPopup}
                        />
            }
            <HorizontalFlex css={"align-items: center; margin-bottom: 20px; padding-bottom: 30px; border-bottom: 2px solid #21262c;"}>
                <PageIcon icon={ProfileIcon}/>
                <PageTitle>Profil</PageTitle>
            </HorizontalFlex>
            <FlexContainer>
                <ContentContainer css={"margin-right: 10px; max-width: 25%; max-height: fit-content;"}>
                    {user !== null ? 
                        <div>
                            <HorizontalFlex css="align-items: center;">
                                <Icon src={ProfileIcon} />
                                <VerticalFlex>
                                    <Title size={12}>Namn</Title>
                                    <Title size={12} css="font-weight: normal;">{user.name}</Title>
                                </VerticalFlex>
                            </HorizontalFlex>
                            <HorizontalFlex css="align-items: center; margin-top: 20px;">
                                <Icon src={EmailIcon} />
                                <VerticalFlex>
                                    <Title size={12}>Email</Title>
                                    <Title size={12} css="font-weight: normal;">{user.email}</Title>
                                </VerticalFlex>
                            </HorizontalFlex>
                            <HorizontalFlex css="align-items: center; margin-top: 20px;">
                                <Icon src={IDIcon} />
                                <VerticalFlex>
                                    <Title size={12}>ID</Title>
                                    <Title size={12} css="font-weight: normal;">{user.id}</Title>
                                </VerticalFlex>
                            </HorizontalFlex>
                            <HorizontalFlex css="align-items: center; margin-top: 20px;">
                                <Icon src={PhoneIcon} />
                                <VerticalFlex>
                                    <Title size={12}>Telefon</Title>
                                    <Title size={12} css="font-weight: normal;">{user.phone ?? <i>Ange telefonnummer</i>}</Title>
                                </VerticalFlex>
                            </HorizontalFlex>
                        </div>
                        :
                        <LoadingComponent message="Hämtar användare..."/>
                    }
                    <ActionButton
                        color=      {Color.blue}
                        hoverColor= {Color.lightBlue}
                        onClick=    {navUpdateProfile}
                    >Ändra profil</ActionButton>
                    <ActionButton
                        color=      {Color.red}
                        hoverColor= {Color.lightRed}
                        onClick=    {logout}
                        css=        {"margin-top: 10px;"}
                    >Logga ut</ActionButton>
                </ContentContainer>
                <ContentContainer css={"width: auto;"}>
                    {organisation !== null ? 
                        <div>
                            <Title size={13} css="font-weight: normal;">Organisation</Title>
                            <Title size={18}>{organisation.name}</Title>   
                            {orgMembers !== null && 
                                <UserList 
                                    users=              {orgMembers} 
                                    includeID=          {false}
                                    isEmployeeTable=    {isEmployee}
                                    deletable=          {false}
                                    editable=           {false}
                                /> 
                            }
                        </div>
                        :
                        <LoadingComponent message="Hämtar organisation..."/>
                    }
                </ContentContainer>
            </FlexContainer>
        </ViewContainer>
    )
}

export default ProfileView;