import React from "react";
import styled from "styled-components";

import { HorizontalFlex } from "../pandora/styled";
import Notifications from "./Notifications";
import SearchBar from "./SearchBar";


const Container = styled(HorizontalFlex)`
    position: relative;

    align-items: center;

    left: 0;
    right: 0;
    padding: 20px;
    padding-left: 25px;
    padding-right: 25px;
    height: 30px;

    overflow: visible;
    z-index: 10;

    background: rgb(33, 38, 44);    
`

const TopBar = (): React.ReactElement => {
    return (   
        <Container>
            <SearchBar />
            <Notifications />
        </Container>
    )
}

export default TopBar;