import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Heimdall from "../api/Heimdall";
import APIRequest from "../api/APIRequest";
import { INotification, NotificationPriority } from "../api/ASuiteModels";

import Color from "resources/colors";

import { VerticalFlex } from "../pandora/styled";
import { VerticalExpand, FadeAnimation } from "../pandora/animations";
import NotificationContainer from "./Notification";
import LoadingComponent from "./LoadingComponent";
import NoDataComponent from "./NoDataComponent";

import DeleteIcon from "../resources/images/delete-icon.png";
import NotificationIcon from "../resources/images/notification-icon.svg";
import HasNotificationsIcon from "../resources/images/has-notifications-icon.svg";

 


const Container = styled(VerticalFlex)<{opened: boolean}>`
    position: absolute;
    align-items: center;
    
    width: ${props => props.opened ? "20%" : "30px"};
    height: fit-content;
    top: 10px;
    right: 10px;

    padding: 10px;
    margin-left: 15px;

    z-index: 10;
    border-radius: 5px;

    max-height: 500px;
    overflow-y: visible;

    background-color: ${props => props.opened ? Color.darkGrey : "transparent"};

    transition: 0.3s ease;

    h2 {
        opacity: 0;
        margin-bottom: 5px;

        font-weight: bold;
        font-size: 25px;
        color: white;
        text-align: left;

        animation: ${FadeAnimation} 0.5s 1;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
    }
`

const OpenButton = styled("div")<{opened: boolean, hasNotifications: boolean}>`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-bottom: 10px;

    background-color: ${props => props.opened ? Color.extraLightGrey : "transparent"};
    background-image: url(${props => props.hasNotifications ? HasNotificationsIcon : NotificationIcon});
    background-size: 70% 70%;
    background-position: center;
    background-repeat: no-repeat;
    
    transform: rotateZ(${props => props.opened ? "20deg" : "0"});

    cursor: pointer;
    transition: 0.3s ease;

    &: hover {
        background-color: ${Color.extraLightGrey};
    }
`

const NotificationList = styled(VerticalFlex)`
    height: 0px;
    margin-top: -100%;

    overflow: auto;

    animation: ${VerticalExpand} 0.5s 1;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;

    &: first-child {
        margin-top: 0px;
    }

    ::-webkit-scrollbar {
        width: 0px;
    }   
`

const ClearButton = styled.div<{color: string, hoverColor: string}>`
    position: absolute;
    left: 10px;
    top: 10px;

    width: 30px;
    height: 30px; 
    border-radius: 5px;
    margin-bottom: 10px;

    line-height: 30px;
    opacity: 0;
    cursor: pointer;

    background-color: ${props => props.color};
    background-image: url(${DeleteIcon});
    background-size: 50% 50%;
    background-position: center;
    background-repeat: no-repeat;

    animation: ${FadeAnimation} 0.5s 1;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;

    transition: 0.3s ease;

    &: hover {
        background-color: ${props => props.hoverColor};
    }
`




const Notifications = (): React.ReactElement => {

    // State
    const [opened, setOpened] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<INotification[] | null>(null);

    // Effects
    useEffect(() => {

        /**
         * Request user's notifications from API
         */
        const getNotifications = async () => { 
            try {
                const params = {seen: false}
                const res: INotification[] = await Heimdall.request(APIRequest.getNotifications, params) as INotification[]
                setNotifications(res);

                // Automatically open Notification
                // list if there is an urgent or high priority notification
                if (res.some(n => n.priority === NotificationPriority.High || n.priority === NotificationPriority.Urgent))
                    setTimeout(() => setOpened(true), 2000);
            } catch (e) {
                console.log(e)
            }
        }

        getNotifications()
    }, [])


    // Actions
    const clear = async () => { 
        await Heimdall.request(APIRequest.markAllNotificationsSeen);
        setNotifications([]);
    }

    const toggleOpen = () => { setOpened(!opened) }

    const navigate = (notification: INotification) => {
        window.open(notification.link, '_blank');
        remove(notification);
    }

    /**
     * Request removal, i.e. marking 
     * a notification as seen. 
     */
    const remove = async (notification: INotification) => {
        try {
            await Heimdall.request(APIRequest.markNotificationSeen, {id: notification.id})
            const _notifications = [...notifications];
            _notifications.splice(notifications.indexOf(notification), 1);
            setNotifications(_notifications);
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <Container opened={opened}> 
            <OpenButton 
                hasNotifications =  {!(notifications === null || notifications.length === 0 || opened)}
                opened=             {opened}
                onClick=            {toggleOpen}
            />
            {opened && notifications.length !== 0 &&
                <ClearButton
                    color=      {Color.lightGrey}
                    hoverColor= {Color.red}
                    onClick=    {clear}
                />
            }
            {opened && notifications !== null && 
                <div style={{overflow: "hidden", width: "100%"}}>
                    {notifications.length !== 0 &&  <h2>Notiser</h2>}
                    {notifications.length !== 0 && 
                        <NotificationList>
                            {notifications.map(n => 
                                    <NotificationContainer 
                                        key=                {n.id}
                                        notification=       {n}
                                        onClick=            {navigate}
                                        isSelected=         {false}
                                        removeNotification= {remove}
                                    />
                                )
                            }                
                        </NotificationList>
                    }
                    {notifications.length == 0 &&
                        <NoDataComponent message="Inga notiser"/>
                    }
                </div>
            }
            {opened && notifications === null &&
                <LoadingComponent 
                    message=    "Hämtar notiser"
                    css=        {"flex-direction: column;"}
                />
            }
        </Container>
    )
}

export default Notifications;

