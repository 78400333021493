import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Heimdall from "../../api/Heimdall";
import APIRequest from "../../api/APIRequest";
import { IUser } from "../../api/ASuiteModels";

import SecurityIcon from "../../resources/images/security-icon.svg";
import ProfileIcon from "../../resources/images/profile-icon.svg";
import Color from "../../resources/colors";

import { PageTitle, PageIcon, VerticalFlex, HorizontalFlex, Button, Input,  ErrorDiv, SuccessDiv, ViewContainer } from "../../pandora/styled";
import GenericPopup, { GenericPopupProps } from "../../components/GenericPopup";
import LoadingComponent from "../../components/LoadingComponent";


const ProfileViewContainer = styled(ViewContainer)`
    display: flex;
    flex-direction: row;
`

const ContentContainer = styled(VerticalFlex)`
    position: relative;
    height: auto;
    width: auto;
    padding: 30px;

    margin-right: 10px; 
    max-height: fit-content;
    max-width: 80%;

    border-radius: 5px;
    background-color: ${Color.darkGrey};

    @media only screen and (max-width: 1200px) {
        max-width: 50%;
    }

    @media only screen and (max-width: 900px) {
        max-width: 100%;
    }
`

const ActionButton = styled(Button)<{css?: string}>`
    width: auto;
    height: 40px;
    
    margin-top: 40px;

    line-height: 40px;
    font-size: 12px;

    ${props => props.css ?? ""}
`

const DataInput = styled(Input)`
    background-color: ${Color.lightGrey};
    margin-top: 10px;
`

const Title = styled("h1")<{size: number, css?: string}>`
    margin: 0;
    margin-top: 5px;
    text-align: left;
    font-size: ${props => props.size}px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: white;
    word-break: break-all;

    ${props => props.css ?? ""};
`



const UpdateProfileView = (): React.ReactElement => {

    // State
    const [user, setUser] = useState<IUser | null>(null);

    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmedNewPassword, setConfirmedNewPassword] = useState<string>("");

    // Since request and UX aspects such as displaying
    // submits and requests applies to both the profile updates
    // and password updates, these states are stored as 
    // password and profile specific. 
    const [error, setError] = useState<{profile: string, password: string}>({profile: null, password: null});
    const [requestSuccess, setRequestSuccess] = useState<{profile: boolean, password: boolean}>({profile: false, password: false});
    const [submitActive, setSubmitActive] = useState<{profile: boolean, password: boolean}>({profile: false, password: false});

    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Set doc title
    document.title = `Ändra profil | ASuite`

    // Effects
    useEffect(() => {

        /**
         * Request profile data for current user. 
         */
        const getProfileData = async () => { 
            try {
                const _user: IUser = await Heimdall.request(APIRequest.auth) as IUser;                
                setUser(_user);
            } catch (e) {
                const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
                setPopup({
                    title: "Ett fel uppstod.",
                    message: message,
                    color: Color.red,
                    setPopup: setPopup
                }) 
            }
        }

        getProfileData()
    }, [])


    // Actions

    /**
     * Request user profile update. 
     */
    const updateProfile = async () => {
        // Check credentials
        if (user.name === "" || user.phone === "") {
            setError({...error, profile: "Ett eller flera fält är tomma."})
            return
        }

        // Request password update
        try {
            setSubmitActive({...submitActive, profile: true})
            const payload = {
                phone: user.phone,
                name: user.name
            }
            const _ = await Heimdall.request(APIRequest.updateCurrentUser, payload)

            // Update state for password request result
            setRequestSuccess({...requestSuccess, profile: true})
            setError({...error, profile: null});
        } catch (e) {
            const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
            setError({...error, profile: message});
        }
        setSubmitActive({...submitActive, profile: false})
    }

    /**
     * Request user password update. 
     */
    const updatePassword = async () => {

        // Check credentials
        if (oldPassword === "" || newPassword === "" || confirmedNewPassword === "") {
            setError({...error, password: "Ett eller flera fält är tomma."})
            return
        }

        if (newPassword !== confirmedNewPassword) {
            setError({...error, password: "Nytt lösenord och bekräftelse matchar inte."})
            return
        }

        // Request password update
        try {
            setSubmitActive({...submitActive, password: true})
            const payload = new URLSearchParams({
                oldpassword: oldPassword,
                newpassword: newPassword
            })
            const _ = await Heimdall.request(APIRequest.updatePassword, payload)

            // Update state for password request result
            setRequestSuccess({...requestSuccess, password: true})
            setError({...error, password: null});
        } catch (e) {
            const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
            setError({...error, password: message});
        }
        setSubmitActive({...submitActive, password: false})
    }


    return (
        <ProfileViewContainer>
            {popup && <GenericPopup 
                            title=          {popup.title}
                            message=        {popup.message}
                            color=          {popup.color}
                            setPopup=       {setPopup}
                        />
            }
            <VerticalFlex css={"margin-right: 20px;"}>
                <HorizontalFlex css={"align-items: center; margin-bottom: 40px;"}>
                    <PageIcon icon={ProfileIcon}/>
                    <PageTitle>Ändra profil</PageTitle>
                </HorizontalFlex>
                {user !== null && 
                    <ContentContainer css={"min-height: 245px;"}>
                        {error.profile && <ErrorDiv>{error.profile}</ErrorDiv>}
                        {requestSuccess.profile && <SuccessDiv>{`Profil uppdaterad.`}</SuccessDiv>}
                        <div>
                            <Title size={15}>Namn</Title>
                            <DataInput 
                                focusBorder=    {true}
                                type=           "text"
                                placeholder=    "Namn"
                                value=          {user.name}
                                onChange=       {(e) => setUser({...user, ...{name: e.target.value}})}
                            />
                            <Title size={15} css={"margin-top: 20px;"}>Telefonnummer</Title>
                            <DataInput 
                                focusBorder=    {true}
                                type=           "text"
                                placeholder=    "Ange telefonnummer"
                                value=          {user.phone ?? ""}
                                onChange=       {(e) => setUser({...user, ...{phone: e.target.value}})}
                            />
                        </div>
                        {!submitActive.profile &&
                            <ActionButton
                                color=      {Color.green}
                                hoverColor= {Color.lightGreen}
                                onClick=    {updateProfile}
                                css=        {"margin-top: 35px;"}
                            >Uppdatera profil</ActionButton>
                        }
                        {submitActive.profile && 
                            <LoadingComponent 
                                message=    {"Uppdaterar profil..."}
                                css=        {"background-color: transparent;"}
                            />
                        }
                    </ContentContainer>
                }
                {user === null && 
                    <LoadingComponent 
                        message=    {"Hämtar användare..."}
                        css=        {"background-color: transparent;"}
                    />
                }
            </VerticalFlex>
            <VerticalFlex>
                <HorizontalFlex css={"align-items: center; margin-bottom: 40px;"}>
                    <PageIcon icon={SecurityIcon}/>
                    <PageTitle>Ändra lösenord</PageTitle>
                </HorizontalFlex>
                <ContentContainer>
                    {error.password && <ErrorDiv>{error.password}</ErrorDiv>}
                    {requestSuccess.password && <SuccessDiv>{`Lösenord uppdaterat.`}</SuccessDiv>}
                    {!requestSuccess.password && 
                        <div>
                            <DataInput 
                                focusBorder=    {true}
                                borderColor=    {Color.blue}
                                type=           "password"
                                placeholder=    "Nuvarande lösenord"
                                value=          {oldPassword}
                                onChange=       {(e) => setOldPassword(e.target.value)}
                            />
                            <DataInput 
                                focusBorder=    {true}
                                borderColor=    {Color.blue}
                                type=           "password"
                                placeholder=    "Nytt lösenord"
                                value=          {newPassword}
                                onChange=       {(e) => setNewPassword(e.target.value)}
                            />
                            <DataInput 
                                focusBorder=    {true}
                                borderColor=    {Color.blue}
                                type=           "password"
                                placeholder=    "Bekräfta nytt lösenord"
                                value=          {confirmedNewPassword}
                                onChange=       {(e) => setConfirmedNewPassword(e.target.value)}
                            />
                        </div>
                    }
                    {!submitActive.password && !requestSuccess.password &&
                        <ActionButton
                            color=      {Color.blue}
                            hoverColor= {Color.lightBlue}
                            onClick=    {updatePassword}
                        >Ändra lösenord</ActionButton>
                    }
                    {submitActive.password && 
                        <LoadingComponent 
                            message=    {"Uppdaterar lösenord..."}
                            css=        {"background-color: transparent;"}
                        />
                    }
                </ContentContainer>
            </VerticalFlex>
        </ProfileViewContainer>
    )
}

export default UpdateProfileView;