import React, { useState } from "react";
import styled from "styled-components";

import SearchIcon from "../resources/images/magnifying-glass.png";
import { HorizontalFlex, Input } from "../pandora/styled";


const Container = styled(HorizontalFlex)`
    position: relative;
    padding: 0px;
    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: flex-start;
`

const Icon = styled.img`
    max-width: 20px; 
    height: auto;
    margin: 0;
`   

const SearchInput = styled(Input)`
    height: 100%;
    margin: 0;
    margin-left: 10px;
    background: transparent;

    font-size: 16px;
    font-weight: bold;
`


interface SearchBarProps {


}

const SearchBar = (props: SearchBarProps): React.ReactElement => {

    // State
    const [searchText, setSearchText] = useState<string>("");


    const search = async () => { console.log("NOT IMPLEMENTED") }

    const onKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation()
            search()
        }
    }

    return (   
        <Container>
            <Icon src={SearchIcon} />
            <SearchInput 
                focusBorder=    {false}
                fontSize=       {12}
                type=           "text"
                placeholder=    "Sök ASuite..."
                value=          {searchText}
                onChange=       {(e) => setSearchText(e.target.value)}
                onKeyPress=     {onKeyPress}
            />
        </Container>
    )
}

export default SearchBar;