import React from "react";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/sv";

import { ASuiteServices } from "../site/services";
import { INotification, NotificationPriority } from "../api/ASuiteModels";

import { VerticalFlex, HorizontalFlex } from "../pandora/styled";

import Color from "../resources/colors";
import FlagIcon from "../resources/images/flag-icon.svg";
import CloseIcon from "../resources/images/close-icon.png";



const NotificationContainerDiv = styled(HorizontalFlex)<{highPriority: boolean, isSelected: boolean}>`
    align-items: center;
    height: fit-content;

    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    border-sizing: border-box;

    padding-top: ${props => props.isSelected ? 30 : 10}px;
    padding-bottom: ${props => props.isSelected ? 30 : 10}px;

    background-color: ${props => props.isSelected ? Color.extraLightGrey : Color.lightGrey};
    cursor: pointer;

    ${props => props.highPriority ? `
        background-image: url(${FlagIcon});
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) 50%;
        background-size: 15px 15px;
    ` : ""}

    transition: 0.3s ease;

    h3 {
        margin-left: 10px;
        margin-right: 10%;
        text-align: left;
        font-size: 10px;
        font-weight: normal;
        color: white;
        margin-top: 0px;
    }

    h4 {
        color: white;
        font-size: 9px;
        margin: 0px;
        margin-left: 10px;
        margin-bottom: 4px;
        text-align: left;
    }

    img {
        width: 20px;
        height: 20px;
    }

    &: hover {
        background-color: ${Color.extraLightGrey};
    }

    &: active {
        transform: scale(0.98);
    }
`

const RemoveButton = styled.div`
    height: 15px;
    min-width: 15px;
    margin-right: 5px;
    margin-left: 10px;

    background-image: url(${CloseIcon});
    background-repeat: no-repeat;
    background-size: 10px 10px;
    background-position: center;
`


interface NotificationContainerProps {
    notification: INotification;
    isSelected: boolean;
    onClick: (notification: INotification) => void;
    removeNotification?: (notification: INotification) => void;
}

const NotificationContainer = (props: NotificationContainerProps): React.ReactElement => {

    // State
    const { notification, isSelected, onClick, removeNotification } = props;
    const icon = ASuiteServices.get(notification.service).icon
    const isHighPriority = notification.priority === NotificationPriority.High || notification.priority === NotificationPriority.Urgent

    moment.relativeTimeThreshold("m", 60)
    const timestamp = moment.utc(notification.time).local().locale("sv").fromNow()

    // Actions
    const remove = (e) => {
        e.preventDefault()
        e.stopPropagation()
        removeNotification(notification);
    }

    return (
        <NotificationContainerDiv 
            highPriority=   {isHighPriority}
            isSelected=     {isSelected}
            onClick=        {() => onClick(notification)}
        >
            <img src={icon}/>
            <VerticalFlex>
                <h4>{timestamp}</h4>
                <h3>{notification.action}</h3>
            </VerticalFlex>
            {!isHighPriority && removeNotification !== undefined &&
                <RemoveButton 
                    onClick={remove}
                />
            }
        </NotificationContainerDiv>
    )
}

export default NotificationContainer;