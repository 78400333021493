import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Auth from "../../site/auth";

import Heimdall from "../../api/Heimdall";
import APIRequest from "../../api/APIRequest";
import { IOrganisation } from "../../api/ASuiteModels";
import { ASuiteServices, IService } from "../../site/services";

import DashboardIcon from "../../resources/images/dashboard-icon.svg";

import { PageTitle, PageIcon, HorizontalFlex, ViewContainer } from "../../pandora/styled";

import Activity from "../../components/Activity";
import ServiceBox from "../../components/ServiceBox";
import LoadingComponent from "../../components/LoadingComponent";
import NoDataComponent from "../../components/NoDataComponent";




const DashboardViewContainer = styled(ViewContainer)`
    overflow: visible;
`

const ServiceGrid = styled.div`
    position: relative;
    display: grid;
    grid-auto-rows: 150px;
    
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 150px;
    column-gap: 20px;
    row-gap: 20px;

    transition: 0.3s ease;
    overflow: visible;

    @media only screen and (max-width: 1500px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media only screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 770px) {
        grid-template-columns: 1fr;
    }
`


const Dashboard = (): React.ReactElement => {

    // State

    // Set default services for employees only, as Atritec employees should have access
    // to all services. 
    const defaultServices: IService[] | null = Auth.isEmployee() ? ASuiteServices.all() : null;
    const [services, setServices] = useState<IService[] | null>(defaultServices);

    // Set doc title
    document.title = `Dashboard | ASuite`

    // Effects
    useEffect(() => {

        // Request services for current user.
        // Services are tied to an organisation.         
        const getServices = async () => { 
            try {
                // Request services from Heimdall. 
                const res: IOrganisation = await Heimdall.request(APIRequest.getCurrentOrganisation) as IOrganisation

                // Retrieve ASuite service instance metadata 
                // configurations from retrieved services for organisation. 
                const _services = ASuiteServices.all().filter(s => res["services"].includes(s.type));
                setServices(_services);
            } catch (e) {
                console.log(e)
            }
        }

        // Since all Atritec employee accounts have access 
        // to all services, only request services if default services are set. 
        if (defaultServices === null) getServices()
    }, [])


    return (
        <DashboardViewContainer>
            <HorizontalFlex css={"align-items: center; margin-bottom: 20px; padding-bottom: 30px; border-bottom: 2px solid #21262c;"}>
                <PageIcon icon={DashboardIcon}/>
                <PageTitle>Dashboard</PageTitle>
            </HorizontalFlex>
            {services === null && 
                <LoadingComponent message={"Hämtar tjänster..."} />
            }
            {services !== null && services.length === 0 && 
                <NoDataComponent message={"Inga tjänster registrerade för din organisation."} />
            }
            {services !== null && services.length !== 0 &&
                <ServiceGrid>
                    {services.map((s: IService) => 
                        <a 
                            key=    {s.type}
                            href=   {s.link} 
                            target= "_blank" 
                            rel=    "noreferrer"
                        >
                            <ServiceBox 
                                key=        {s.type}
                                service=    {s}
                            />
                        </a>
                        )
                    }
                </ServiceGrid>
            }
            <Activity />
        </DashboardViewContainer>
    )
}

export default Dashboard;