

// Enumerations

export enum Role {
    //An enum representing different levels of access for employees 
    Admin = "Admin",
    Basic = "Basic"
}

export enum NotificationPriority {
    // An enum representing the priority of notifications
    Urgent = "Urgent",
    High = "High",
    Medium = "Medium",
    Low = "Low"
}

export enum Service {
    // ASuite services
    GeoDoc = "GeoDoc",
    Geoserver = "Geoserver",
    LCSOnline = "LCSOnline",
    LidarPlatform = "LidarPlatform"
}


// ASuite Models

export interface ASuiteModel {
    id: string;
}

export interface IUser extends ASuiteModel{
    name: string
    email: string
    isEmployee: boolean
    phone: string | null
    organisationID: string
    role: Role | null
}

export interface IOrganisation extends ASuiteModel {
    name: string
    services: string[]
}

export interface INotification extends ASuiteModel {
    priority: NotificationPriority
    action: string
    content: string
    link: string
    userID: string
    triggeringUser: IUser
    service: Service
    time: Date
    seen: boolean
}

export interface APIError {
    code: number
    message: string
}

