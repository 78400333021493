import React, { useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";

import Heimdall from "../../api/Heimdall";
import APIRequest from "../../api/APIRequest";
import { Site, SiteRoute } from "../../routes/site";

import Color from "../../resources/colors";
import LogoIcon from "../../resources/images/asuite-logo.png";
import BackgroundImage from "../../resources/images/background-image-2.jpg";

import { ItemContainerAnimation } from "../../pandora/animations";
import { HorizontalFlex, VerticalFlex, Input, Button, ShadowLayer, LinkButton, Logo, ErrorDiv, SuccessDiv } from "../../pandora/styled";
import LoadingComponent from "../../components/LoadingComponent";



const Background = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url(${BackgroundImage});
    background-size: cover;
`

const Container = styled(VerticalFlex)`
    position: relative;
    width: 20%;
    min-width: 350px;
    height: 100%;
    box-sizing: border-box;
`

const LoginContainer = styled(VerticalFlex)`
    margin-top: 20%;
    width: 300px;
    height: auto;
    padding: 25px;
    border-radius: 5px;
    align-items: center;

    background: rgba(44, 50, 58, 0.6);
    animation: ${ItemContainerAnimation} 0.8s 1;
`

const Title = styled("h1")<{size: number, css?: string}>`
    margin: 0;
    text-align: center;
    font-size: ${props => props.size}px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: white;

    ${props => props.css ?? ""};
`



const RequestPasswordResetView = (): React.ReactElement => {

    const REDIRECT_DELAY: number = 5000;
    const history = useHistory()

    // State
    const [email, setEmail] = useState<string | null>("");
    
    const [error, setError] = useState<string | null>(null)
    const [requestSuccess, setRequestSuccess] = useState<boolean>(false);

    const [submitActive, setSubmitActive] = useState<boolean>(false);

    // Set doc title
    document.title = `Återställ lösenord | ASuite`

    // Actions
    const requestReset = async () => {

        // Check credentials
        if (email === "") {
            setError("Ingen email adress angiven.")
            return
        }

        // Request password reset 
        try {
            setSubmitActive(true)
            const payload = {email: email}
            const _ = await Heimdall.request(APIRequest.requestPasswordReset, payload)

            // Update state for password request result
            setRequestSuccess(true);
            setError(null);
            setTimeout(() => history.push(SiteRoute.Login), REDIRECT_DELAY)
        } catch (e) {
            setError(e.message);
        }
        setSubmitActive(false);
    }

    /**
     * Bind key press event to action. 
     * @param e 
     */
    const onKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation()
            requestReset()
        }
    }

    return (
        <Background>
            <ShadowLayer>
                <Container>
                    <LoginContainer>
                        <Logo src={LogoIcon} />
                        <Title 
                            size={15}
                            css={"margin-top: 40px;"}
                        >Glömt lösenordet?</Title>
                        <Title 
                            size={12}
                            css={"margin-top: 10px; font-weight: normal; max-width: 80%;"}
                        >Ingen fara! Ange din email adress nedan så kommer en länk för att återställa ditt lösenord inom kort.</Title>
                        <VerticalFlex css={"margin-top: 30px;"}>
                            {error && <ErrorDiv>{error}</ErrorDiv>}
                            {requestSuccess && <SuccessDiv>{`Länk skickad till ${email}`}</SuccessDiv>}
                            {!requestSuccess && 
                                <Input 
                                    focusBorder=    {true}
                                    type=           "email"
                                    placeholder=    "Email"
                                    value=          {email}
                                    onChange=       {(e) => setEmail(e.target.value)}
                                    onKeyPress=     {onKeyPress}
                                />
                            }
                            {!submitActive && !requestSuccess &&
                                <Button 
                                    color=          {Color.green}
                                    hoverColor=     {Color.lightGreen}
                                    css=            {"margin-top: 20px; width: auto; height: 40px;"}
                                    onClick=        {requestReset}
                                >Återställ lösenord</Button>
                            }
                            {submitActive && 
                                <LoadingComponent 
                                    message=    {"Återställer lösenord..."}
                                    css=        {"background-color: transparent;"}
                                />
                            }
                        </VerticalFlex>
                        <HorizontalFlex css={"justify-content: center; margin-top: 20px; width: 100%;"}>
                            <Link to={SiteRoute.Login} style={{textDecoration: "none"}}>
                                <LinkButton>Logga in</LinkButton>
                            </Link>
                            <a href={Site.webAbout} rel="noreferrer" style={{textDecoration: "none"}}>
                                <LinkButton>Om oss</LinkButton>
                            </a>
                            <a href={Site.webContact} rel="noreferrer" style={{textDecoration: "none"}}>
                                <LinkButton>Kontakt</LinkButton>
                            </a>
                        </HorizontalFlex>
                    </LoginContainer>
                </Container>
            </ShadowLayer>
        </Background>
    );
}

export default RequestPasswordResetView;