import React, { useState, useEffect } from 'react';
import {
    useHistory,
    useLocation
} from "react-router-dom";

import Auth from "./site/auth";
import ServiceRedirect, { RedirectRequest } from "./site/redirect";
import { SiteRoute, Site } from "./routes/site";

import './App.css';

import LoadingScreen from "./components/LoadingScreen";
import AuthenticatedView from "./views/AuthenticatedView";
import PublicRoutes from "./views/PublicRoutes";



function App(): React.ReactElement {

    const history = useHistory()
    const location = useLocation()
    
    // State
    const [query, _] = useState<RedirectRequest | null>(ServiceRedirect.getRedirect(location))
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [authenticated, setAuthenticated] = useState<boolean>(false);

    /**
     * If trying to access a private route 
     * and user is not authenticated, push to Login page. 
     */
    const isAuthenticated = (): boolean => {
        const isPrivateRoute: boolean = Site.inPrivateRoutes(location.pathname)
        if (!authenticated && isPrivateRoute) {
            history.push(SiteRoute.Login)
            return 
        }
        return authenticated && isPrivateRoute
    }

    // Effects
    useEffect(() => {

        // Verify Heimdall connection by
        // authenticating user token. If Heimdall not reachable or 
        // token has expired (i.e. response.status !== 200), redirect to 
        // ASuite login route. 
        const verifyAuth = async () => {

            // Verify token 
            let isVerified: boolean = false;
            const token = Auth.init()
            if (token) isVerified = await Auth.verify();

            // Set authentication state
            setAuthenticated(isVerified);
            setIsLoading(false);

            // Determined proper route
            const currRoute: string = location.pathname
            let query: string = location.search
            let navRoute: string;

            // If user is not authenticated and is trying to access a private 
            // route, redirect to login page. 
            if (Site.routeExists(currRoute)) {
                navRoute = isVerified || Site.inPublicRoutes(currRoute) ? currRoute : SiteRoute.Login
            } else {
                // if route doesn't exist, redirect to
                // 404 page with requested route as a 
                // query string. 
                navRoute = SiteRoute.Error
                query = `?r=${currRoute.replace("/", "")}`
            }
            
            // Push route with querystrings
            history.push({ 
                pathname: navRoute,
                search: query
            })
        }
        
        verifyAuth()
    }, []);

    /**
     *  If user has been authenticated after a 
     * service redirect to ASuite because of authentication 
     * token not being set. Construct redirect link and navigate to that 
     * URL. This is normally a Service URl with the preserved query strings. 
    */
    useEffect(() => {
        if (authenticated && query !== null && ServiceRedirect.INITIAL_LOAD) {
            ServiceRedirect.INITIAL_LOAD = false;
            const params = (query.params !== "" ? `?${query.params}` : "")
            const route = (query.route !== null ? `${query.route}` : "")
            const link = `${query.url}/${route}${params}`
            window.location.assign(link)
        }
    }, [authenticated])


    const ui = () => {        
        return (
            isLoading ? 
            <LoadingScreen /> 
            : 
            <div className="App">
                <PublicRoutes 
                    authenticated=      {authenticated}
                    setAuthenticated=   {setAuthenticated}
                />
                {isAuthenticated() && 
                    <AuthenticatedView /> 
                }
            </div>
        )
    }

    return ui();
}

export default App;
