import React from "react";
import styled from "styled-components";

import { SiteView, AppViewConfig } from "../routes/site";

import DashboardIcon from "../resources/images/dashboard-icon.svg";
import ProfileIcon from "../resources/images/profile-icon.svg";
import UsersIcon from "../resources/images/users-icon.svg";
import OrganisationsIcon from "../resources/images/organisation-icon.svg";
import AtritecIcon from "../resources/images/atritec-icon.svg";

import Color from "../resources/colors";

import { Button } from "../pandora/styled";
import { ItemContainerAnimation } from "../pandora/animations";



const ViewOptionIcon = styled("div")<{icon: string, color: string}>`
    width: 20px;
    height: 20px;
    margin-right: 15px;

    border-width: 0;
    outline: none;

    cursor: pointer;

    background-size: cover;
    mask: url(${props => props.icon}) no-repeat 50% 50%;
    mask-size: cover;

    background-color: ${props => props.color};

    transition: 0.3s ease;
`

const ViewOptionButtonContainer = styled(Button)<{optionColor: string, selected: boolean}>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
    width: 100%;

    padding: 5px;
    padding-left: 15px;

    border-left: 3px solid ${props => props.selected ? props.optionColor : "transparent"};

    transition: background 0.8s, border 0.3s ease;
    animation: ${ItemContainerAnimation} 0.3s 1;

    &: hover {
        border-left: 3px solid ${props => props.optionColor};
    }

    &: hover ${ViewOptionIcon} {
        background-color: ${props => props.optionColor};
    }
`

const ViewOptionTitle = styled("h3")<{isSelected: boolean}>`
    margin: 0;

    text-align: left;
    font-weight: bold;
    font-size: 11px;
    color: ${props => props.isSelected ? "white" :  Color.extraLightGrey};

    transition: 0.3s ease;
`




const SiteViewIconMap = {
    [SiteView.Dashboard]: DashboardIcon,
    [SiteView.Profile]: ProfileIcon,
    [SiteView.Users]: UsersIcon,
    [SiteView.Organisations]: OrganisationsIcon,
    [SiteView.Atritec]: AtritecIcon
}

const SiteViewColorMap = {
    [SiteView.Dashboard]: Color.green,
    [SiteView.Profile]: Color.blue,
    [SiteView.Users]: Color.purple,
    [SiteView.Organisations]: Color.red,
    [SiteView.Atritec]: Color.lightGreen
}


interface ViewOptionProps {
    viewConfig: AppViewConfig;
    isSelected: boolean;
    navigate: (v: SiteView) => void
}

const ViewOptionButton = (props: ViewOptionProps): React.ReactElement => {

    const iconColor: string = props.isSelected ? SiteViewColorMap[props.viewConfig.view] : Color.extraLightGrey

    return (
        <ViewOptionButtonContainer
            selected=           {props.isSelected}
            color=              {Color.darkGrey}
            hoverColor=         {Color.extraLightGrey}
            optionColor=        {SiteViewColorMap[props.viewConfig.view]}
            onClick=            {() => props.navigate(props.viewConfig.view)}
        >
            <ViewOptionIcon 
                color=   {iconColor}
                icon=    {SiteViewIconMap[props.viewConfig.view]}
            />
            <ViewOptionTitle isSelected={props.isSelected}>
                {props.viewConfig.name}
            </ViewOptionTitle>
        </ViewOptionButtonContainer>
    )
}

export default ViewOptionButton;