import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/sv";

import Heimdall from "../api/Heimdall";
import APIRequest from "../api/APIRequest";
import { ASuiteServices } from "site/services";
import { INotification, NotificationPriority } from "../api/ASuiteModels";

import Color from "resources/colors";

import { HorizontalFlex, VerticalFlex, PageIcon, PageTitle } from "../pandora/styled";
import { FadeAnimation } from "../pandora/animations";
import NotificationContainer from "./Notification";
import LoadingComponent from "./LoadingComponent";
import NoDataComponent from "./NoDataComponent";

import FlagIcon from "../resources/images/flag-icon.svg";
import MailIcon from "../resources/images/email-icon.png";
import EyeIcon from "../resources/images/eye-icon.svg";
import PhoneIcon from "../resources/images/phone-icon.svg";
import ActivityIcon from "../resources/images/list-icon.svg";




const Container = styled(VerticalFlex)`
    width: auto;
    height: 60vh;
    margin-top: 50px;
    padding: 20px;
    padding-bottom: 0px;

    border-radius: 5px;

    max-height: 500px;
    overflow-y: hidden;

    background-color: ${Color.darkGrey};
    transition: 0.3s ease;

    h2 {
        opacity: 0;
        margin-bottom: 5px;

        font-weight: bold;
        font-size: 25px;
        color: white;
        text-align: left;

        animation: ${FadeAnimation} 0.5s 1;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
    }
`

const NotificationList = styled(VerticalFlex)`
    height: auto;
    overflow: auto;
    width: 25%;
    overflow-y: auto;
    padding-bottom: 40px;

    div: first-of-type {
        margin-top: 0px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.4); 
    }   
`

const NotificationPreview = styled.div`
    margin-left: 20px;
    width: 75%;
    height: calc(100% - 20px);

    background-color: ${Color.lightGrey};
    border-radius: 5px;
    color: white; 

    box-sizing: border-box;
    padding: 20px;

    h1 {
        font-size: 20px;
        text-align: left;
        margin: 10px 0 20px 0;
    }

    h2 {
        font-size: 15px; 
        font-weight: normal;
        margin: 0 0 0 15px;
    }

    h3 {
        text-align: left;
        font-size: 12px;
        font-weight: bold;
        margin: 20px 0 0 0;
        color: ${Color.extraLightGrey};
    }

    p {
        text-align: left;
        font-size: 14px;
        margin: 0 0 20px 0;
    }

    b {
        margin-right: 5px;
    }

    .user-marker {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 35px;
        height: 35px;
        border-radius: 50%;
        font-weight: bold;
        background-color: red;
    }
`

const NotificationLink = styled("a")<{color: string, hoverColor: string, icon: string}>`
    display: flex;
    align-items: center;

    min-height: 30px;
    padding: 5px;
    padding-right: 10px;
    margin-top: 10px;
    border-radius: 5px;

    background-color: ${props => props.color};
    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-position: 10px 50%;
    background-size: 15px 15px;

    text-decoration: none;
    text-indent: 35px;
    font-size: 12px;
    color: white;

    transition: 0.3s ease;

    &: hover {
        background-color: ${props => props.hoverColor};
    }
`

const NotificationButton = styled(NotificationLink)``




const Activity = (): React.ReactElement => {

    // State
    moment.relativeTimeThreshold("m", 60)

    const [notifications, setNotifications] = useState<INotification[] | null>([]);
    const [selectedNotification, setSelectedNotification] = useState<INotification | null>(null);
    
    // Effects
    useEffect(() => {

        /**
         * Request user's notifications from API.
         * Get only "seen" notifications within the last 
         * 30 days. 
         */
        const getNotifications = async () => { 
            try {
                const params = {
                    seen: true, 
                    fromtime: moment().subtract(30, "days").utc().format()
                }
                const res: INotification[] = await Heimdall.request(APIRequest.getNotifications, params) as INotification[]
                setNotifications(res);
                
                if (res !== null && res.length > 0) 
                    setSelectedNotification(res[0])
            } catch (e) {
                console.log(e)
            }
        }

        getNotifications()
    }, [])


    // Actions
    const select = (notification: INotification) => {
        setSelectedNotification(notification);
    }

    const markUnread = async () => {
        try {
            await Heimdall.request(APIRequest.markNotificationUnseen, {id: selectedNotification.id})
            const _notifications = [...notifications];
            _notifications.splice(notifications.indexOf(selectedNotification), 1);
            setNotifications(_notifications);
            setSelectedNotification(_notifications[0]);
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <Container> 
            <HorizontalFlex css={"align-items: center; margin-bottom: 20px; height: 5vh;"}>
                <PageIcon icon={ActivityIcon} size={25}/>
                <PageTitle fontSize={25}>Aktivitet</PageTitle>
            </HorizontalFlex>
            {notifications === null &&
                <LoadingComponent 
                    message=    "Hämtar notiser"
                    css=        {"flex-direction: column;"}
                />
            }
            {notifications !== null && notifications.length !== 0 &&
                <HorizontalFlex css={"height: calc(100% - 5vh - 20px);"}>
                    <NotificationList>
                        {notifications.map(n => 
                                <NotificationContainer 
                                    key=            {n.id}
                                    notification=   {n}
                                    onClick=        {select}
                                    isSelected=     {selectedNotification?.id === n.id}
                                />
                            )
                        }                
                    </NotificationList>
                    {selectedNotification && 
                        <NotificationPreview>
                            <HorizontalFlex style={{justifyContent: "space-between"}}>
                                <VerticalFlex style={{minWidth: "50%", position: "relative", marginRight: "20px"}}>
                                    <HorizontalFlex style={{alignItems: "center"}}>
                                        <div 
                                            className="user-marker"
                                            style={{backgroundColor: ASuiteServices.get(selectedNotification.service).color}}
                                        >{selectedNotification.triggeringUser.name.charAt(0)}</div>
                                        <h2>{selectedNotification.triggeringUser.name}</h2>
                                    </HorizontalFlex>
                                    <h3>{moment.utc(selectedNotification.time).local().locale("sv").fromNow()}</h3>
                                    <h1>{selectedNotification.action}</h1>
                                    <p>{selectedNotification.content}</p>
                                </VerticalFlex>
                                <VerticalFlex style={{width: "auto", padding: "10px"}}>
                                    {(selectedNotification.priority === NotificationPriority.High || 
                                    selectedNotification.priority === NotificationPriority.Urgent) && 
                                        <NotificationLink
                                            icon=           {FlagIcon}
                                            color=          {Color.darkGrey}
                                            hoverColor=     {Color.extraLightGrey}
                                            style=          {{pointerEvents: "none", marginBottom: 20}}
                                        ><b>Prioritet</b></NotificationLink>
                                    }
                                    <NotificationLink
                                        icon=       {MailIcon}
                                        color=      {Color.darkGrey}
                                        hoverColor= {Color.extraLightGrey}
                                        href=       {`mailto: ${selectedNotification.triggeringUser.email}`} 
                                    ><b>Maila</b>{selectedNotification.triggeringUser.email}</NotificationLink>
                                    <NotificationLink
                                        icon=       {PhoneIcon}
                                        color=      {Color.darkGrey}
                                        hoverColor= {Color.extraLightGrey}
                                        href=       {`tel: ${selectedNotification.triggeringUser.phone}`} 
                                    ><b>Ring</b>{selectedNotification.triggeringUser.phone}</NotificationLink>
                                    <NotificationButton
                                        icon=       {EyeIcon}
                                        color=      {Color.red}
                                        hoverColor= {Color.lightRed}
                                        style=      {{cursor: "pointer"}}
                                        onClick=    {markUnread}
                                    ><b>Markera oläst</b></NotificationButton>
                                    <NotificationLink
                                        icon=       {ASuiteServices.get(selectedNotification.service).icon}
                                        color=      {ASuiteServices.get(selectedNotification.service).color}
                                        hoverColor= {ASuiteServices.get(selectedNotification.service).hoverColor}
                                        href=       {selectedNotification.link} 
                                    ><b>Se ändring i {ASuiteServices.get(selectedNotification.service).name}</b></NotificationLink>
                                </VerticalFlex>
                            </HorizontalFlex>
                        </NotificationPreview>
                    }
                </HorizontalFlex>
            }
            {notifications.length == 0 &&
                <NoDataComponent message="Inga notiser"/>
            }
        </Container>
    )
}

export default Activity;

