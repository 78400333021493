import React from "react";
import {
    Switch,
    Route
} from "react-router-dom";

import { SiteRoute } from "../routes/site";
import { PublicRoute } from "../routes/PublicRoute";

import LoginView from "./unauthenticated/LoginView";
import RequestPasswordResetView from "./unauthenticated/RequestPasswordResetView";
import ConfirmRegistrationView from "./unauthenticated/ConfirmRegistrationView";
import SetNewPasswordView from "./unauthenticated/SetNewPasswordView";
import ErrorView from "./404";



interface PublicRoutesProps {
    authenticated: boolean;
    setAuthenticated: (value: boolean) => void
}

const PublicRoutes = (props: PublicRoutesProps): React.ReactElement => {
    return (
        <Switch>
            <PublicRoute 
                authenticated=  {props.authenticated}
                path=           {SiteRoute.Login} 
                component=      {(cProps) => (<LoginView {...cProps} setAuth={props.setAuthenticated}/>)} 
                exact 
            />
            <PublicRoute    
                authenticated=  {props.authenticated} 
                path=           {SiteRoute.RequestPasswordReset} 
                component=      {RequestPasswordResetView} 
                exact 
            />
            <PublicRoute 
                authenticated=  {props.authenticated} 
                path=           {SiteRoute.ConfirmRegistration} 
                component=      {ConfirmRegistrationView}
            />
            <PublicRoute
                authenticated=  {props.authenticated} 
                path=           {SiteRoute.SetNewPassword} 
                component=      {SetNewPasswordView} 
                exact 
            />
            <Route 
                path=           {SiteRoute.Error}
                component=      {ErrorView}
            />
        </Switch>   
    )
}

export default PublicRoutes;