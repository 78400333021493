import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Heimdall from "../../api/Heimdall";
import APIRequest from "../../api/APIRequest";
import { IOrganisation } from "../../api/ASuiteModels";

import UserIcon from "../../resources/images/users-icon.svg";

import { PageTitle, PageIcon, VerticalFlex, HorizontalFlex, ViewContainer } from "../../pandora/styled";
import Color from "../../resources/colors";

import GenericPopup, { GenericPopupProps } from "../../components/GenericPopup";
import OrganisationUserItem from "../../components/organisation/OrganisationUserItem";
import LoadingComponent from "../../components/LoadingComponent";
import NoDataComponent from "../../components/NoDataComponent";


const FlexContainer = styled(VerticalFlex)`
    overflow: hidden;
    width: auto;
`

const OrganisationListContainer = styled(VerticalFlex)`
    overflow-y: auto;
`



const UserView = (): React.ReactElement => {

    // State
    const [organisations, setOrganisations] = useState<IOrganisation[] | null>(null);
    const [focusOrg, setFocusOrg] = useState<IOrganisation | null>(null);
    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Set doc title
    document.title = `Användare | ASuite`

    // Effects
    useEffect(() => {

        /**
         * Retrieve organisations. 
         */
        const getOrganisations = async () => { 
            try {
                const _orgs: IOrganisation[] = await Heimdall.request(APIRequest.getOrganisations) as IOrganisation[]    
                setOrganisations(_orgs);
            } catch (e) {
                const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
                setPopup({
                    title: "Ett fel uppstod.",
                    message: message,
                    color: Color.red,
                    setPopup: setPopup
                })            
            }
        }

        getOrganisations()
    }, [])

    return (
        <ViewContainer>
            {popup && <GenericPopup 
                            title=          {popup.title}
                            message=        {popup.message}
                            color=          {popup.color}
                            setPopup=       {setPopup}
                        />
            }
            <HorizontalFlex css={"align-items: center; margin-bottom: 20px; padding-bottom: 30px; border-bottom: 2px solid #21262c;"}>
                <PageIcon icon={UserIcon}/>
                <PageTitle>Användare</PageTitle>
            </HorizontalFlex>
            <FlexContainer>
                <OrganisationListContainer>
                    {organisations !== null ? organisations.map(o => 
                            <OrganisationUserItem 
                                key=            {o.name}
                                isAtritec=      {false}
                                organisation=   {o}
                                focused=        {focusOrg === o}
                                setFocusOrg=    {setFocusOrg}
                                setPopup=       {setPopup}
                            />
                        )   
                        :
                        <LoadingComponent message={"Hämtar användare..."} />                 
                    }
                    {organisations !== null && organisations.length === 0 && 
                        <NoDataComponent message={"Inga organisationer hittades."} />
                    }
                </OrganisationListContainer>
            </FlexContainer>
        </ViewContainer>
    )
}

export default UserView;