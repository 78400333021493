import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import qs from "query-string";

import { Site, SiteRoute } from "../routes/site";

import LogoIcon from "../resources/images/asuite-logo.png";
import BackgroundImage from "../resources/images/background-image-5.jpg";

import { ItemContainerAnimation } from "../pandora/animations";
import { HorizontalFlex, VerticalFlex, ShadowLayer, LinkButton, Logo } from "../pandora/styled";



const Background = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url(${BackgroundImage});
    background-size: cover;
`

const Container = styled(VerticalFlex)`
    position: relative;
    width: 20%;
    min-width: 350px;
    height: 100%;
    box-sizing: border-box;
`

const MessageContainer = styled(VerticalFlex)`
    margin-top: 20%;
    width: 300px;
    height: auto;
    padding: 25px;
    border-radius: 5px;
    align-items: center;

    background: rgba(44, 50, 58, 0.6);
    animation: ${ItemContainerAnimation} 0.8s 1;
`

const Title = styled("h1")<{size: number, bold: boolean, css?: string}>`
    margin: auto;
    margin-top: 10px;
    max-width: 60%;

    text-align: center;
    font-size: ${props => props.size}px;
    font-weight: ${props => props.bold ? "bold" : "normal"};
    font-family: 'Montserrat';
    color: white;

    ${props => props.css ?? ""}
`


const ErrorView = (): React.ReactElement => {

    const location = useLocation()

    // Parser requested page from query string 
    // to display in 404 error message. 
    const queryParams = qs.parse(location.search)
    const { r: requestedPage } = queryParams;    

    // Set doc title
    document.title = `Uh oh! | ASuite`

    return (
        <Background>
            <ShadowLayer>
                <Container>
                    <MessageContainer>
                        <Logo src={LogoIcon}/>
                        <Title 
                            size=   {25} 
                            bold=   {true}
                            css=    {"margin-top: 20px; margin-bottom: 20px;"}
                        >Oh boy!</Title>
                        <Title 
                            size={13} 
                            bold={false}
                        >{`Sidan "${requestedPage}" kunde inte hittas.`}</Title>
                        <HorizontalFlex css={"justify-content: center; margin-top: 40px; width: 100%;"}>
                            <Link to={SiteRoute.Dashboard} style={{textDecoration: "none"}}>    
                                <LinkButton>ASuite</LinkButton>
                            </Link>
                            <Link to={SiteRoute.Login} style={{textDecoration: "none"}}>
                                <LinkButton>Logga in</LinkButton>
                            </Link>
                            <a href={Site.webAbout} rel="noreferrer" style={{textDecoration: "none"}}>
                                <LinkButton>Om oss</LinkButton>
                            </a>
                            <a href={Site.webContact} rel="noreferrer" style={{textDecoration: "none"}}>
                                <LinkButton>Kontakt</LinkButton>
                            </a>
                        </HorizontalFlex>
                    </MessageContainer>
                </Container>
            </ShadowLayer>
        </Background>
    );
}

export default ErrorView;