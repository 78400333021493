import React from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";

import Auth from "../site/auth";
import { SiteView, AppViewConfig, Site } from "../routes/site";

import ASuiteLogo from "../resources/images/asuite-logo.png";
import Color from "../resources/colors";

import ViewOptionButton from "../components/ViewOptionButton";
import { VerticalFlex } from "../pandora/styled";



const Container = styled(VerticalFlex)`
    position: relative;
    width: 150px;
    height: auto;
    padding: 20px;
    bottom: 0;
    top: 0;

    padding-right: 50px;
    padding-bottom: 0px;
    align-items: center;

    background: ${Color.darkGrey};
`

const Logo = styled.img`
    max-width: 85%; 
    height: auto;
    margin: 0;
`   

const ViewOptionList = styled(VerticalFlex)`
    margin-top: 30px;
    align-items: center;
`


const SideBar = (): React.ReactElement => {

    const location = useLocation()
    const history = useHistory()

    // State
    const selectedView: SiteView = SiteView[location.pathname];
    const views: AppViewConfig[] = Site.AppViews.all().filter(s => !s.admin || (s.admin && Auth.isAdmin()))

    // Actions

    // Push selected route on view selection. 
    const navigate = (v: SiteView) => { 
        if (v !== selectedView) 
            history.push(v as unknown as string)
    }

    return (   
        <Container>
            <Logo src={ASuiteLogo} />
            <ViewOptionList>
                {views.map((viewConfig: AppViewConfig) => 
                        <ViewOptionButton 
                            //@ts-ignore
                            isSelected=     {SiteView[viewConfig.view] as SiteView === selectedView}
                            viewConfig=     {viewConfig}
                            key=            {viewConfig.view}
                            navigate=       {() => navigate(viewConfig.view)}
                        />
                    )
                }
            </ViewOptionList>
        </Container>
    )
}

export default SideBar;