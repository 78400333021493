import React from "react";
import styled from "styled-components";
import {
    Switch,
    Route,
} from "react-router-dom";

import Auth from "../site/auth";
import { SiteRoute } from "../routes/site";
import AdminRoute from "../routes/AdminRoute";

import Color from "../resources/colors";

import SideBar from "../components/SideBar";
import TopBar from "../components/TopBar";
import { HorizontalFlex, VerticalFlex } from "../pandora/styled";

import Dashboard from "./authenticated/Dashboard";
import ProfileView from "./authenticated/ProfileView";
import UpdateProfileView from "./authenticated/UpdateProfileView";
import UserView from "./authenticated/UserView";
import OrganisationView from "./authenticated/OrganisationView";
import AtritecView from "./authenticated/AtritecView";


const Container = styled(HorizontalFlex)`
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`

const ParentViewContainer = styled(VerticalFlex)`
    position: absolute;
    left: calc(150px + 20px + 25px);

    height: 100vh;
    width: auto;
    right: 0;
    
    border-radius: 25px 0px 0px 25px;

    box-sizing: border-box;
    overflow: hidden;
`

const ContentContainer = styled.div`
    position: relative;
    background: ${Color.lightGrey};

    height: 100%;
    padding: 50px 30px 30px;

    overflow: auto;
`


const AuthenticatedView = (): React.ReactElement => {

    const isAdmin: boolean = Auth.isAdmin()

    return (
        <Container>
            <SideBar />
            <ParentViewContainer>
                <TopBar />  
                <ContentContainer>
                    <Switch>
                        <Route
                            path=           {SiteRoute.Dashboard} 
                            component=      {Dashboard} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.Profile} 
                            component=      {ProfileView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.UpdateProfile} 
                            component=      {UpdateProfileView} 
                            exact 
                        />
                        <AdminRoute
                            isAdmin=        {isAdmin}
                            path=           {SiteRoute.Users} 
                            component=      {UserView} 
                            exact 
                        />
                        <AdminRoute
                            isAdmin=        {isAdmin}
                            path=           {SiteRoute.Organisations} 
                            component=      {OrganisationView} 
                            exact 
                        />
                        <AdminRoute
                            isAdmin=        {isAdmin}
                            path=           {SiteRoute.Atritec} 
                            component=      {AtritecView} 
                            exact 
                        />
                    </Switch>
                </ContentContainer>
            </ParentViewContainer>
        </Container>
    )
}

export default AuthenticatedView;