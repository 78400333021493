
enum Color {
    darkGrey = "rgb(22, 25, 29)",
    lightGrey = "rgb(44, 50, 58)",
    extraLightGrey = "rgb(88, 100, 116)",

    green = "rgb(25, 139, 116)",
    lightGreen = "rgb(49, 165, 141)",

    blue = "rgb(92, 125, 204)",
    lightBlue = "rgb(119, 146, 212)",

    red = "rgb(255, 80, 80)",
    lightRed = "rgb(255, 102, 102)",

    purple = "rgb(153, 102, 255)",
    lightPurple  = "rgb(170, 128, 255)",

    yellow = "rgb(252, 173, 3)"
}

export default Color;


export const alpha = (color: string, alpha: number): string => {
    const c: string = color.substring(
        color.lastIndexOf("(") + 1, 
        color.lastIndexOf(")")
    );
    return `rgba(${c}, ${alpha})`
}