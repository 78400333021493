
import GeoDocThumbnail from "../resources/images/geodoc-background.png";
import GeoServerThumbnail from "../resources/images/geoserver-background.jpg";
import LCSOnlineThumbnail from "../resources/images/lcs-online-background.png";
import LidarPlatformThumbnail from "../resources/images/lidar-background.jpg";

import GeoDocIcon from "../resources/images/geodoc-icon.svg";
import GeoServerIcon from "../resources/images/geoserver-icon.svg";
import LCSOnlineIcon from "../resources/images/lcs-online-icon.svg";
import LidarPlatformIcon from "../resources/images/lidar-platform-icon.svg";

import Color from "../resources/colors";
import { Service } from "../api/ASuiteModels";



/**
 * Service configuration and 
 * metadata protocol. 
 */
export interface IService {
    type: Service
    name: string
    identifier: string
    link: string
    thumbnail: string
    icon: string
    color: string
    hoverColor?: string
}

/**
 * Links mapped to each service. 
 * Ideally, these are connected to environment
 * variables for smoother development. 
 */
const ServiceLinks: {[key in Service]: string} = {
    [Service.GeoDoc]: process.env.REACT_APP_GEODOC_URL,
    [Service.Geoserver]: "https://geoserver.atritec.se",
    [Service.LCSOnline]: process.env.REACT_APP_LCSONLINE_URL,
    [Service.LidarPlatform]: "https://lidar.atritec.se"
}


/**
 * Define ASuiteServices with associated 
 * meta data for user in application. 
 */
export class ASuiteServices {

    public static GeoDoc: IService = {
        type: Service.GeoDoc,
        name: "GeoDoc",
        identifier: "geodoc",
        link: ServiceLinks[Service.GeoDoc],
        thumbnail: GeoDocThumbnail,
        icon: GeoDocIcon,
        color: Color.green,
        hoverColor: Color.lightGreen,
    }

    public static Geoserver: IService = {
        type: Service.Geoserver,
        name: "GeoServer",
        identifier: "geoserver",
        link: ServiceLinks[Service.Geoserver],
        thumbnail: GeoServerThumbnail,
        icon: GeoServerIcon,
        color: Color.red,
        hoverColor: Color.lightRed,
    }

    public static LCSOnline: IService = {
        type: Service.LCSOnline,
        name: "LCS Online",
        identifier: "lcsonline",
        link: ServiceLinks[Service.LCSOnline],
        thumbnail: LCSOnlineThumbnail,
        icon: LCSOnlineIcon,
        color: Color.purple,
        hoverColor: Color.lightPurple,
    }

    public static LidarPlatform: IService = {
        type: Service.LidarPlatform,
        name: "Lidar Plattform",
        identifier: "lidarplatform",
        link: ServiceLinks[Service.LidarPlatform],
        thumbnail: LidarPlatformThumbnail,
        icon: LidarPlatformIcon,
        color: Color.blue,
        hoverColor: Color.lightBlue,
    }

    // Map service enum to service meta data 
    // configurations. 
    private static map = {
        [Service.GeoDoc]: ASuiteServices.GeoDoc,
        [Service.Geoserver]: ASuiteServices.Geoserver,
        [Service.LCSOnline]: ASuiteServices.LCSOnline,
        [Service.LidarPlatform]: ASuiteServices.LidarPlatform
    }

    // Retrieve all services. 
    public static all = (): IService[] => {
        return [
            ASuiteServices.GeoDoc,
            ASuiteServices.Geoserver,
            ASuiteServices.LCSOnline,
            ASuiteServices.LidarPlatform
        ]
    }

    // Retrieve service metadata configuration 
    // for given service. 
    public static get = (s: Service): IService => {
        return ASuiteServices.map[s]
    }
}
