import React, { useState } from 'react';
import styled from "styled-components";

import { IUser, Role } from "../api/ASuiteModels";
import Color from "../resources/colors";

import { VerticalFlex } from "../pandora/styled";


const Container = styled(VerticalFlex)<{opened: boolean}>`

    width: 100%;
    height: fit-content;    
    margin: 0;

    box-sizing: border-box;
    border-radius: 5px;

    justify-content: flex-start;
    transition: 0.3s ease;

    padding: 0px;
    background-color: ${props => props.opened ? Color.lightGrey : "transparent"};

    transition: 0.3s ease;

    ${props => props.opened ? `
        padding: 10px;
        margin-bottom: 20px;
    `:""}
`

const RoleContainer = styled("div")<{color: string, animate: boolean}>`
    position: relative;
    height: 30px;
    width: auto;
    
    border-radius: 5px;
    margin: 0;

    line-height: 30px;
    font-size: 10px;
    font-weight: bold;
    color: white;

    background-color: ${props => props.color};

    transition: 0.3s ease;

    &: hover {  
        &: before {
            display: block;
        }
    }

    &: before {
        position: absolute;
        display: none;
        
        z-index: 1;

        content: "";

        height: 100%;
        width: 100%;
        
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.2);

        transition: 0.3s ease;
    }
`

const SelectableRoleContainer = styled(RoleContainer)`
    margin-top: 10px;
`

const Title = styled("h1")<{size: number, css?: string}>`
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: left;
    font-size: ${props => props.size}px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: white;

    ${props => props.css ?? ""};
`


const RoleColorMap = {
    [Role.Admin]: Color.red,
    [Role.Basic]: Color.yellow
}

interface RoleDropdownProps {
    currentRole: Role,
    user: IUser,
    editUser?: (user: IUser, update: Record<string, string>) => void;
}

/**
 * Drop down menu for role selection 
 * in user editing. 
 */
const RoleDropdown = (props: RoleDropdownProps): React.ReactElement => {

    const [opened, setOpened] = useState<boolean>(false);

    // Actions
    const open = (e) => {
        e.stopPropagation()
        setOpened(!opened)
    }

    // Handle role selection 
    const selectRole = (e, role: Role) => {
        e.stopPropagation()
        props.editUser(props.user, {role: role})
        setOpened(false);
    }   

    return (
        <Container
            opened=     {opened}
            onClick=    {open}
        >
            {!opened && <RoleContainer 
                color=      {RoleColorMap[props.currentRole]}
                animate=    {false}
                onClick=    {open}
            >{props.currentRole}</RoleContainer>}
            {opened && 
                <VerticalFlex>
                    <Title 
                        size=   {11}
                    >Välj roll</Title>
                    {Object.keys(Role).map(r => 
                        <SelectableRoleContainer 
                            key=        {r}
                            color=  {   RoleColorMap[Role[r]]}
                            animate=    {true}
                            onClick=    {(e) => selectRole(e, Role[r])}
                        >{r}</SelectableRoleContainer>
                    )}
                </VerticalFlex>
            }
        </Container>
    )
}


export default RoleDropdown;