import React from 'react';
import styled from "styled-components";

import { IService } from "../site/services";

import Color, { alpha } from "../resources/colors";
import { ViewEntranceAnimation, ScaleAnimation, FadeAnimation, SlideAnimation } from "../pandora/animations";
import { HorizontalFlex } from "../pandora/styled";


const ServiceImage = styled("div")<{image: string, color: string}>`
    width: 100%;
    height: 100%;

    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.8;

    transition: 0.5s ease;

    &: before {
        position: absolute;
        display: none;
        
        content: "";

        height: 100%;
        width: 100%;
        
        top: 0;
        left: 0;
        background: ${props => alpha(props.color, 0.7)};

        animation: ${FadeAnimation} 0.5s 1;
    }
`

const Title = styled.h3`
    margin: 0;
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
    color: white; 

    transition: 0.3s ease;
`

const IconContainer = styled("div")`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 50px;
    min-height: 50px;
    width: 50px;
    min-width: 50px;
    border-radius: 12.5vh;
    margin-right: 20px;

    background-color: white;

    transition: 0.3s ease;
    animation: ${ScaleAnimation} 0.3s 1;
`

const Icon = styled("div")<{icon: string, color: string}>`
    width: 20px;
    height: 20px;

    border-width: 0;
    outline: none;

    cursor: pointer;

    background-size: cover;
    mask: url(${props => props.icon}) no-repeat 50% 50%;
    mask-size: cover;

    background-color: ${props => props.color};

    transition: 0.3s ease;
`

const ContentFlex = styled(HorizontalFlex)`
    position: absolute;
    display: flex;
    
    justify-content: flex-start;
    align-items: center;

    width: auto;
    height: auto;
    left: 10%;
    right: 25%;
    top: 25%;
    bottom: 25%;

    z-index: 5;

    transition: 0.3s ease;
`

const OpenContainer = styled("div")<{color: string}>`
    position: absolute;
    display: none;

    font-size: 12px;
    font-weight: bold;
    color: white;
    line-height: 30px;

    bottom: 0;
    right: 0;
    left: 0;
    height: 20%;
    background-color: ${props => props.color};

    border-radius: 95% 95% 0px 0px;

    transition: 0.3s ease;
    animation: ${SlideAnimation} 0.3s 1;
    
    &: hover {
        height: 25%;
    }
`

const Container = styled("div")<{color: string}>`
    position: relative;
    display: inline-block;

    width: 100%;
    height: 100%;
    border-radius: 5px;

    overflow: hidden;
    user-select: none;
    cursor: pointer;
    background-color: ${props => props.color};

    transition: 0.3s ease;
    animation: ${ViewEntranceAnimation} 0.5s 1;

    &: hover {
        transform: scale(1.03);
        box-shadow: 0px 10px 25px ${alpha(Color.darkGrey, 0.8)};
    }

    &: active {
        transform: scale(0.98);
    }

    &: hover ${ServiceImage} {
        transform: scale(1.2);
    }

    &: hover ${IconContainer} {
        background: white;
    }

    &: hover ${Title} {
        color: white;
    }

    &: hover ${OpenContainer} {
        display: block;
    }

    &: hover ${ContentFlex} {   
        transform: translate(0px, -10px);
    }

    &:hover ${ServiceImage}:before, &: focus ${ServiceImage}: before {
        display: block;
    }
`


interface ServiceBoxProps {
    service: IService;
}

const ServiceBox = (props: ServiceBoxProps): React.ReactElement => {

    const { service } = props;

    return (
        <Container color={service.color}>
            <ContentFlex>
                <IconContainer>
                    <Icon
                        icon=  {service.icon}
                        color= {service.color}
                    />
                </IconContainer>
                <Title>{service.name}</Title>
            </ContentFlex>
            <ServiceImage 
                image=  {service.thumbnail} 
                color=  {service.color}
            />
            <OpenContainer 
                color=  {service.color}
            >Öppna</OpenContainer>
        </Container>
    )
}


export default ServiceBox;