import React from "react";
import styled from "styled-components";

import Animation from "../resources/images/loading-animation.svg";
import { HorizontalFlex } from "../pandora/styled";

import Color from "../resources/colors";


const Container = styled(HorizontalFlex)<{css?: string}>`
    align-items: center;
    justify-content: center;

    margin-top: 15px;
    border-radius: 5px;
    background-color: ${Color.lightGrey};

    ${props => props.css ?? ""}
`

const AnimationContainer = styled.img`
    width: 60px;
    height: 60px;
`

const Title = styled.h1`
    margin: 0;
    margin-left: 14px;
    text-align: left;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: white;
`


interface LoadingProps {
    message?: string
    css?: string
}

const LoadingComponent = (props: LoadingProps): React.ReactElement => {
    return (
        <Container css={props.css}>
            <AnimationContainer src={Animation} />
            <Title>{props.message}</Title>
        </Container>
    )
}

export default LoadingComponent;