import React from "react";
import { 
    Route,
    Redirect
} from "react-router-dom";

import { SiteRoute } from "./site";



/**
 * Customised route component.
 * Render route conditionally for user being  
 * autenticated. Otherwise, redirect to Dashboard if trying to access 
 * a public route that requires an UNAUTHENTICATED user.
 * This means that these routes are not accessible for 
 * a logged in user. 
 */
export const PublicRoute = ({component, authenticated, ...rest}: any): React.ReactElement => {
    const routeComponent = (props: any) => (
        !authenticated
            ? React.createElement(component, props)
            : <Redirect to={SiteRoute.Dashboard}/>
    );
    return <Route {...rest} render={routeComponent}/>;
};
