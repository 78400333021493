import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Heimdall from "../../api/Heimdall";
import APIRequest from "../../api/APIRequest";
import { IOrganisation} from "../../api/ASuiteModels";

import OrganisationIcon from "../../resources/images/organisation-icon.svg";
import Color from "../../resources/colors";

import { PageTitle, PageIcon, VerticalFlex, HorizontalFlex, Button, Input, ViewContainer } from "../../pandora/styled";
import { ItemContainerAnimation } from "../../pandora/animations";

import GenericPopup, { GenericPopupProps } from "../../components/GenericPopup";
import OrganisationAdminItem from "../../components/organisation/OrganisationAdminItem";
import LoadingComponent from "../../components/LoadingComponent";
import NoDataComponent from "../../components/NoDataComponent";


const AddOrganisationContainer = styled(VerticalFlex)`
    position: relative;
    height: auto;
    width: auto;
    padding: 30px;

    border-radius: 5px;
    background-color: ${Color.darkGrey};

    animation: ${ItemContainerAnimation} 0.3s 1;
`

const FlexContainer = styled(VerticalFlex)`
    overflow: hidden;
    width: auto;
`

const OrganisationListContainer = styled(VerticalFlex)`
    margin-top: 20px;
    overflow-y: visible;
`

const Title = styled("h1")<{size: number, css?: string}>`
    margin: 0;
    margin-top: 5px;
    text-align: left;
    font-size: ${props => props.size}px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: white;

    ${props => props.css ?? ""};
`


const OrganisationView = (): React.ReactElement => {

    // State
    const [organisations, setOrganisations] = useState<IOrganisation[] | null>(null);
    const [focusOrg, setFocusOrg] = useState<IOrganisation | null>(null);

    const [isAddingActive, setAddingActive] = useState<boolean>(false);
    const [newOrgName, setNewOrgName] = useState<string | null>(null);

    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Set doc title
    document.title = `Organisationer | ASuite`

    // Effects
    useEffect(() => {

        /**
         * Request all organisations from Heimdall. 
         */
        const getOrganisations = async () => { 
            try {   
                const _orgs: IOrganisation[] = await Heimdall.request(APIRequest.getOrganisations) as IOrganisation[]    
                setOrganisations(_orgs);
            } catch (e) {
                const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
                setPopup({
                    title: "Ett fel uppstod.",
                    message: message,
                    color: Color.red,
                    setPopup: setPopup
                }) 
            }
        }

        getOrganisations()
    }, [])

    // Actions

    // Toggle organisation creation state in UI
    const setAdding = () => {
        setNewOrgName("");
        setAddingActive(!isAddingActive);
    }

    /**
     * Request organisation adding. 
     */
    const addOrganisation = async () => {
        try {
            if (newOrgName === null || newOrgName.length === 0) 
                throw new Error("Inget organisationsnamn angivet.")
            const newOrg: IOrganisation = await Heimdall.request<IOrganisation>(APIRequest.createOrganisation, {name: newOrgName}) as IOrganisation
            
            // Add organisation
            const _orgs = [...organisations, newOrg];
            setOrganisations(_orgs)
            setNewOrgName("")

            setPopup({
                title: "Organisation skapad.",
                message: `Organisationen ${newOrg.name} skapades.`,
                color: Color.green,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Object.keys(e).includes("response") ? e.response.data.detail : e.message;
            setPopup({
                title: "Ett fel uppstod.",
                message: message,
                color: Color.red,
                setPopup: setPopup
            }) 
        }
    }


    return (
        <ViewContainer>
            {popup && <GenericPopup 
                            title=          {popup.title}
                            message=        {popup.message}
                            color=          {popup.color}
                            setPopup=       {setPopup}
                        />
            }
            <HorizontalFlex css={"align-items: center; margin-bottom: 20px; padding-bottom: 30px; border-bottom: 2px solid #21262c;"}>
                <PageIcon icon={OrganisationIcon}/>
                <PageTitle>Organisationer</PageTitle>
            </HorizontalFlex>
            <FlexContainer>
                <Button
                    color=      {!isAddingActive ? Color.green : Color.red}
                    hoverColor= {Color.extraLightGrey}
                    css=        {"width: 120px; padding-left: 10px; padding-right: 10px; font-size: 12px;"}
                    onClick=    {setAdding}
                >{!isAddingActive ? "+ Lägg till" : "Avbryt"}</Button>
                {isAddingActive && 
                    <AddOrganisationContainer 
                        css={"margin-top: 15px;"}
                    >
                        <Title size={12}>Lägg till organisation</Title>
                        <HorizontalFlex css={"margin-top: 15px;"}>
                            <Input 
                                focusBorder=    {false}
                                type=           "text"
                                placeholder=    "Namn"
                                value=          {newOrgName}
                                onChange=       {(e) => setNewOrgName(e.target.value)}
                                fontSize=       {12}
                                css=            {`background-color: ${Color.lightGrey}; margin-top: 0px; margin-right: 15px;`}
                            />   
                            <Button
                                color=      {Color.green}
                                hoverColor= {Color.lightGreen}
                                css=        {"width: 50px; font-size: 20px;"}
                                onClick=    {addOrganisation}
                            >+</Button>
                        </HorizontalFlex>
                    </AddOrganisationContainer>
                }
                <OrganisationListContainer>
                    {organisations !== null ? organisations.map(o => 
                            <OrganisationAdminItem 
                                key=            {o.name}
                                organisation=   {o}
                                focused=        {focusOrg === o}
                                setFocusOrg=    {setFocusOrg}
                                setPopup=       {setPopup}
                            />
                        )
                        :
                        <LoadingComponent message={"Hämtar organisationer..."}/>
                    }
                    {organisations !== null && organisations.length === 0 &&
                        <NoDataComponent message={"Inga organisationer hittades."} />
                    }
                </OrganisationListContainer>
            </FlexContainer>
        </ViewContainer>
    )
}

export default OrganisationView;