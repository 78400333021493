import React from "react";
import { 
    Route,
    Redirect
} from "react-router-dom";

import { SiteRoute } from "./site";


/**
 * Customised route component.
 * Render route conditionally for user admin 
 * role. Otherwise, redirect to Dashboard if trying to access 
 * admin route without admin access level. 
 */
const AdminRoute = ({component, isAdmin, ...rest}: any): React.ReactElement => {
    const routeComponent = (props: any) => (
        isAdmin
            ? React.createElement(component, props)
            : <Redirect to={SiteRoute.Dashboard}/>
    );
    return <Route {...rest} render={routeComponent}/>;
};

export default AdminRoute;