import Cookies from "universal-cookie";



interface CookieActionResult {
    success: boolean
    error?: Error
}

interface Cookie {
    key: string
    opts?: Record<string, unknown>
}

// Predefine cookies here. 
// Opts. property for ASuiteCookie 
// indicates cookie options such as "domain", "path" etc. 
export class ASuiteCookies {

    public static Auth: Cookie = {
        key: "AUTH",
        opts: process.env.NODE_ENV === "production" ? {"domain": ".atritec.se"} : undefined
    }
}


export class CookieHandler {

    private static cookies: Cookies = new Cookies();

    /**
     * Retrieve cookie by key. 
     * @param cookie Cookie to retrieve.
     */
    public static get(cookie: Cookie): any | null {
        return CookieHandler.cookies.get(cookie.key)
    } 

    /**
     * 
     * options (object): Support all the cookie options from RFC 6265
        path (string): cookie path, use / as the path if you want your cookie to be accessible on all pages
        expires (Date): absolute expiration date for the cookie
        maxAge (number): relative max age of the cookie from when the client receives it in seconds
        domain (string): domain for the cookie (sub.domain.com or .allsubdomains.com)
        secure (boolean): Is only accessible through HTTPS?
        httpOnly (boolean): Can only the server access the cookie?
        sameSite (boolean|none|lax|strict): Strict or Lax enforcement
     * 
     * @param cookie Cookie
     * @param value Value to assign to cookie
     * @param options Cookie options listed above
     */

    public static set(cookie: Cookie, value: string | Record<string, unknown>): CookieActionResult {
        try {
            CookieHandler.cookies.set(cookie.key, value, cookie.opts);
            return { success: true, error: null }
        } catch (error) {
            return { success: false, error: error } 
        }
    }

    /**
     * Clear cookie, remove from browser state. 
     * @param key Cookie key to clear.
     */
    public static remove(cookie: Cookie): CookieActionResult {
        try {
            CookieHandler.cookies.remove(cookie.key, cookie.opts);
            return { success: true, error: null }
        } catch (error) {
            return { success: false, error: error } 
        }
    }
}
