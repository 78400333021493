
/**
 * Define all ASuite routes as enum. 
 */
export enum SiteRoute {
    
    Dashboard = "/",
    Profile = "/profile",
    UpdateProfile = "/update-profile",
    Error = "/404",

    // Administration
    Users = "/users",
    Organisations = "/organisations",
    Atritec = "/atritec",

    // Public
    Login = "/login",
    RequestPasswordReset = "/request-password-reset",
    SetNewPassword = "/set-new-password",
    ConfirmRegistration = "/confirm-registration"
}

// Define all "Authenticated" views, i.e. views 
// rendered inside the ASuite dashboard. 
export enum SiteView {
    Dashboard = SiteRoute.Dashboard,
    Profile = SiteRoute.Profile,
    Users = SiteRoute.Users, 
    Organisations = SiteRoute.Organisations,
    Atritec = SiteRoute.Atritec
}

export interface AppViewConfig {
    view: SiteView,
    name: string,
    admin: boolean
}

export class Site {

    // Website 
    public static webURL: string = "http://www.atritec.se"
    
    public static webContact: string = Site.webURL + "/kontakt"
    public static webAbout: string = Site.webURL + "/om-oss"
    public static web404: string = Site.webURL + "/404"

    // Routes

    /**
     * Check that the given route, r, is a public route. 
     * @param r 
     */
    public static inPublicRoutes = (r: string): boolean => {
        const pubRoutes: string[] = [
            SiteRoute.Error,
            SiteRoute.Login,
            SiteRoute.RequestPasswordReset,
            SiteRoute.SetNewPassword,
            SiteRoute.ConfirmRegistration
        ];
        return pubRoutes.includes(r)
    }

    /**
     * Check that the given route, r, is a private route.
     * I.e., route accessing requires authenticated user session. 
     * @param r 
     */
    public static inPrivateRoutes = (r: string): boolean => {
        const privRoutes: string[] = [
            SiteRoute.Dashboard,
            SiteRoute.Profile,
            SiteRoute.Users,
            SiteRoute.Organisations,
            SiteRoute.Atritec,
            SiteRoute.UpdateProfile
        ];
        return privRoutes.includes(r)
    }

    // Check route is valid, otherwise "404" status. 
    public static routeExists = (r: string): boolean => Site.inPrivateRoutes(r) || Site.inPublicRoutes(r)

    // Views
    public static AppViews = class {

        // Define configurations for 
        // views in ASuite authenticated state
        // application. 

        static Dashboard: AppViewConfig = {
            view: SiteView.Dashboard,
            name: "Dashboard",
            admin: false
        }

        static Profile: AppViewConfig = {
            view: SiteView.Profile,
            name: "Profil",
            admin: false
        }

        static Users: AppViewConfig = {
            view: SiteView.Users,
            name: "Användare",
            admin: true
        }

        static Organisations: AppViewConfig = {
            view: SiteView.Organisations,
            name: "Organisationer",
            admin: true
        }

        static Atritec: AppViewConfig = {
            view: SiteView.Atritec,
            name: "Atritec",
            admin: true
        }

        public static all = (): AppViewConfig[] => {
            return [
                Site.AppViews.Dashboard,
                Site.AppViews.Profile,
                Site.AppViews.Users,
                Site.AppViews.Organisations,
                Site.AppViews.Atritec
            ]
        }
    }
}


